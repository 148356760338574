import { Trans } from '@lingui/macro';
import { useKeycloak } from '@react-keycloak/web';
import useSafeState from '../../utils/safe-state';
import { AxiosResponse } from 'axios';
import React, {ReactNode, useCallback} from 'react';
import {Alert, Avatar, Button, Card, Col, Divider, List, Row, Skeleton, Space, Statistic} from 'antd';
import { useAxios } from '../../utils/hooks';
import InfiniteScroll from "react-infinite-scroll-component";
import {Link} from "react-router-dom";
import {Message} from "../../interfaces/Message";
import {DownloadOutlined} from "@ant-design/icons";
import isAuthorized from "../../utils/is-authorized";
import {MessageDocument} from "#/interfaces/MessageDocument";

//import CargoListScreen from '../cargo/cargo-list';


const LandingScreen = () => {

    var usrname = localStorage.getItem('username');

    return (
            //<CargoListScreen/>
        <div style={{fontSize: "xx-large"}}>Üdvözöllek, <b>{usrname}</b>!</div>
    );
}
export default LandingScreen;
