import {ColumnMain} from "../column-main";
import {Column} from "../column";
import {Trans} from "@lingui/macro";
import React from "react";
import { Tag } from 'antd';

const DeliveryOrderListModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();
    columns.push({key:'id', width: 50, filter:true, sorter:true,title:<Trans>#</Trans>});
    columns.push({
        key:'place',
        width: 50,
        filter:true,
        sorter:true,
        title:<Trans>Place</Trans>,
        render: text => {
            
            let transText;
            if (text === 'EXTERNAL') {
                
                transText = <Trans>External</Trans>;
            } else if (text === 'WAREHOUSE') {
                
                transText = <Trans>Warehouse</Trans>;
            } 
            return (
                <>
                    {transText}
                </>
            );
        },
    });
    columns.push({key:'comment', width: 100, filter:true, sorter:true,title:<Trans>Comment</Trans>});
    columns.push({
        key: 'status',
        width: 200,
        uniqueSelectFilterData: [
            {
                label: <Trans>All</Trans>,
                value: null,
            },
            {
                label: <Trans>PrepareDelivery</Trans>,
                value: 'PREPARE_DELIVERY',
            },
            {
                label: <Trans>Delivered</Trans>,
                value: 'DELIVERED',
            },
        ],
        sorter: true,
        title: <Trans>Status</Trans>,
        render: text => {
            let color = 'gray';
            let transText;
            if (text === 'PREPARE_DELIVERY') {
                color = 'gray';
                transText = <Trans>PrepareDelivery</Trans>;
            } else if (text === 'DELIVERED') {
                color = 'lightblue';
                transText = <Trans>Delivered</Trans>;
            } 
            return (
                <Tag color={color} key={text}>
                    {transText}
                </Tag>
            );
        },
    });
    columns.push({key:'updatedDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Updated Date</Trans>});
    columns.push({key:'updatedBy', width: 150, dateFilter:true, sorter:true,title:<Trans>Updated By</Trans>});
    columns.push({key:'createdDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Created Date</Trans>,direction:"desc"});
    columns.push({key:'createdBy', width: 150, dateFilter:true, sorter:true,title:<Trans>Created By</Trans>});


    return{
        columns:columns,
        url:"/resource/delivery"
    };

}
export default DeliveryOrderListModel();
