import useSafeState from '../../utils/safe-state';
import React, {useEffect, useState } from "react";
import {Form, Row, Col, Input, Button, message, Radio, Space, Spin, Checkbox, CheckboxProps, Popover, Table, Tooltip, Modal, InputNumber} from 'antd';
import {Trans} from "@lingui/macro";
import {useAxios} from "../../utils/hooks";
import {useKeycloak} from "@react-keycloak/web";
import { AxiosResponse } from 'axios';
import DtoTable from "../../components/dto-table/dto-table";


import {antdContext} from "../../utils/antdContext";
import { openNotification } from '../../utils/openNotification';
import DeliveryCargoListModel from "../../models/dto/delivery-cargo-list-model";
import {RightOutlined, SnippetsOutlined, DeleteOutlined} from "@ant-design/icons/lib";
import {Column} from "#/models/column";


interface Interface{
    data?: any;
    onClose?: () => void;
    mode?: "Edit" | "AddNew";
}

const NewDeliveryForm = (props : Interface) => {

    const delivNoteTitleText = <h2><Trans>Deliverable records</Trans></h2>
    const columns : Array<Column> = new Array();
    const [deliveryNoteData, setDeliveryNoteData] = useSafeState<Array<any>>([]);
    
    const [visible, setVisible] = useSafeState(false);
    const qtyText = <Trans>Quantity</Trans>
    const deleteText = <Trans>Are you sure want to delete record?</Trans>

    const { TextArea } = Input;

    const [form] = Form.useForm();
    
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "");

    const [value, setValue] = useState(1);
    const [loading, setLoading] = useSafeState(false);
    const [result, setResult] = useSafeState<boolean>();
    const [isView, setIsView] = useSafeState<boolean>();
    const [hasCargo, setHasCargo] = useSafeState<boolean>(true);
    
    const [isModalVisible, setIsModalVisible] = useSafeState(false);
    const [isDeleteVisible, setIsDeleteVisible] = useSafeState(false);
    const [deleteRecord, setDeleteRecord] = useSafeState(-1);
    const [actQty, setActQty] = useSafeState(0);
    const [actProduct, setActProduct] = useSafeState<any>();
    const [maxQty, setMaxQty] = useSafeState<any>();
    const [newDeliveryDataKey, setNewDeliveryDataKey] = useSafeState<any>();
    
    const delivery = {
        id:props.data.deliveryId
    }
    const queryRec = {
        delivery:delivery,
    }

    const hide = () => {
        setVisible(false);
    };

    columns.push({key:'ownUniqueId',dataIndex:["ownUniqueId"], width: 150, direction: "asc", fixed: "left", sorter:false, title:<Trans>Id</Trans>});
    columns.push({key:'cargoName',dataIndex:["cargoName"], width: 150, direction: "asc", fixed: "left", sorter:false, title:<Trans>Name</Trans>});
    columns.push({key:'quantity',dataIndex:["quantity"], width: 100, direction: "asc", fixed: "left", sorter:false, title:<Trans>Quantity</Trans>});
    columns.push({key:'weight',dataIndex:["weight"], width: 100, direction: "asc", fixed: "left", sorter:false, title:<Trans>Weight</Trans>});
    columns.push({key:'comment',dataIndex:["comment"], width: 100, direction: "asc", fixed: "left", sorter:false, title:<Trans>Comment</Trans>});
    columns.push(
        {
            key: 'action',
            sorter: false,
            width: 50,
            title: <></>,
            render: (_, record) => (
                <Space size="middle">
                    <DeleteOutlined className={"ActionButtons"} style={{color: "#ff4d4f"}} onClick={() => {
                        setNewDeliveryDataKey(record.key);
                        setDeleteRecord(record.id);
                        setIsDeleteVisible(true);
                    }
                    }/>
                </Space>
            ),
        }
    );

    const viewMode = () => {

        if(axiosInstance.current != null) {
            
            axiosInstance.current.get("/resource/delivery/" + props.data.deliveryId, {})
                .then(function (response : AxiosResponse<any>) {

                    let delivery = JSON.parse(JSON.stringify(response.data));
                    
                    form.setFieldsValue({"comment":delivery.comment});
                    delivery.place == 'EXTERNAL' ? form.setFieldsValue({"place":1}) : form.setFieldsValue({"place":2});

                }).catch(function (error :any) {
                    message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                    console.log(error)
                    setLoading(false);
                });

            
                axiosInstance.current.get("/resource/deliverycargo/get-by-delivery/?id=" + props.data.deliveryId, {})
                .then(function (response : AxiosResponse<any>) {

                    let deliveryCargoList: any[] = JSON.parse(JSON.stringify(response.data));
                    let recordList: any[] = new Array();

                    for (let i = 0; i < deliveryCargoList.length; i++) {
                        
                        let deliveryCargo = deliveryCargoList[i];
                        
                        let actRecord = {
                            key: deliveryCargo.id,
                            ownUniqueId: deliveryCargo.cargo.ownUniqueId,
                            weight: deliveryCargo.cargo.weight,
                            cargo: deliveryCargo.cargo.id,
                            delivery: deliveryCargo.delivery.id,
                            quantity: deliveryCargo.quantity,
                            cargoName: deliveryCargo.cargo.cargoName,
                            comment: deliveryCargo.comment,
                            id: deliveryCargo.id
                        }

                        recordList.push(actRecord);
                                
                }

                setDeliveryNoteData(recordList);


                }).catch(function (error :any) {
                    message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                    console.log(error)
                    setLoading(false);
                });

                

        }
    }

    useEffect(() => {
        if (props.mode == 'Edit') {
            
            viewMode();
        }
    }, []);


    const handleFinish = () => {
        

        if(axiosInstance.current != null) {
            setLoading(true);                    
                    
            let comment = form.getFieldValue(["comment"]) == undefined ? "" : "&comment=" + form.getFieldValue(["comment"]);
            let place = form.getFieldValue(["place"]) == 1 ? "?place=EXTERNAL" : "?place=WAREHOUSE";
            let deliveryId = props.data.deliveryId == undefined ? "" : "&id=" + props.data.deliveryId;

            axiosInstance
            .current({
                url: process.env.REACT_APP_API_BASE_URL + '/resource/deliverycargo/saverecords' + place
                +comment
                +deliveryId,
                method: 'POST',
                data: deliveryNoteData
            })
            .then(response => {
                setLoading(false);
                setDeliveryNoteData(new Array());
                setVisible(false);
                setResult(false);
                //disable save button to avoid double saving
                setHasCargo(false);
                openNotification('success','');
            })
            .catch(function (error :any) {
                message.error(antdContext('Hiba a mentés közben')).then(r => {console.log(r)});
                console.log(error)
                setLoading(false);
            })
        }
    }

    const action = (text: string, record: any) => {

        return  <>
            
            <Tooltip placement="top" title={<Trans>Add to Delivery Note</Trans>}>
                <RightOutlined
                    className={"ActionButtons"}
                    style={{color: "#40a9ff"}}
                    onClick={() => showModal(record)
                    }
                />
            </Tooltip>
        </>
    }

    const actions = {
        width: 50,
        render: (text: string, record: any) => action(text, record),
        title: '',
        fixed: 'right',
        align: 'center'
    };

    const showModal = (record: any) => {
        
        setIsModalVisible(true);
        setActQty(record.quantity - record.deliveredQuantity > 0 ? record.quantity - record.deliveredQuantity : 0);
        setMaxQty(record.quantity - record.deliveredQuantity > 0 ? record.quantity - record.deliveredQuantity : 0);
        setActProduct(
            {
                ownUniqueId: record.ownUniqueId,
                weight: record.weight,
                cargoId: record.id,
                quantity: record.quantity,
                cargoName: record.cargoName,
                comment: record.comment,
                deliveryId: props.data.deliveryId

                
            }

        );
    };


    const isAllowed = (): boolean => {

        let sumQty = 0;
        
        for (let i = 0; i < deliveryNoteData.length; i++) {
            if(
                deliveryNoteData[i].cargo == actProduct.cargoId
            ){
                sumQty += deliveryNoteData[i].quantity;
            }
        }
        

        let ret: any = sumQty + actQty <= maxQty;
        let maxDelivQty = maxQty - sumQty;

        if (props.mode == 'Edit') {
            ret = actQty <= maxQty;
            maxDelivQty = maxQty;
        }

        if (!ret) {
            message.error("Maximális kiszállítható mennyiség: " + maxDelivQty + ". db");
        }

        return ret;
    }

    const handleVisibleChange = (newVisible: boolean) => {
        setVisible(newVisible);
    };

    const handleOk = () => {
        
        if(actQty > 0 && actQty <= maxQty && isAllowed()){
            setIsModalVisible(false);
            actProduct.quantity = actQty;
            setDeliveryNoteData([...deliveryNoteData,
                {
                    key: (deliveryNoteData.length + 1).toString(),
                    ownUniqueId: actProduct.ownUniqueId,
                    weight: actProduct.weight,
                    cargo: actProduct.cargoId,
                    delivery: actProduct.deliveryId,
                    quantity: actQty,
                    cargoName: actProduct.cargoName,
                    comment: actProduct.comment,
                    
                    
                }
            ]);
            setVisible(true);
            setHasCargo(true);
        }
        
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleDeleteCancel = () => {
        setIsDeleteVisible(false);
    };

    const handleDeleteOk = () => {
        setIsDeleteVisible(false);
        if (deleteRecord != undefined) {
            if(axiosInstance.current != null) {
                setLoading(true);
                axiosInstance.current.delete("/resource/deliverycargo/deleterecord?id=" + deleteRecord, {})
                    .then(function (response : AxiosResponse<any>) {
                        setLoading(false);
                        const newData = deliveryNoteData.filter(item => item.key !== newDeliveryDataKey);
                        setDeliveryNoteData(newData);
                        if (newData.length == 0) {
                            setHasCargo(false)
                        }
                        openNotification('customSuccess','Sikeres törlés');
                    }).catch(function (error :any) {
                        message.error(antdContext('Hiba a törlés közben')).then(r => {console.log(r)});
                        console.log(error)
                        setLoading(false);
                });
            }
        } else {
            const newData = deliveryNoteData.filter(item => item.key !== newDeliveryDataKey);
            setDeliveryNoteData(newData);
            if (newData.length == 0) {
                setHasCargo(false)
            }
        }
    };

    return(
            <Form id="newdelivery"  layout='vertical' form={form}  onFinish={() => handleFinish()}>
                <Spin spinning={loading}></Spin>
                <Row gutter={24} style={{marginBottom: 10}}>
                    <Col span={24}>
                        <span style={{fontWeight: "bold", fontSize: "large"}}><Trans>Choose an incoming</Trans></span>
                    </Col>
                </Row>
                <Row gutter={24} style={{marginBottom: 10}}>
                    <Col span={24}>
                        <DtoTable
                            tableHeader={
                                <>
                                <Popover
                                    content={
                                        <>
                                            <Table columns={columns} dataSource={deliveryNoteData} bordered/>
                                            <br />
                                            <a onClick={hide}><Trans>Close</Trans></a>
                                        </>
                                    }
                                    title={delivNoteTitleText}
                                    trigger="click"
                                    visible={visible}
                                    onVisibleChange={handleVisibleChange}
                                >
                                    <Button type="primary" style={{float: "right" }}><SnippetsOutlined /><span><Trans>Detail list</Trans></span></Button>
                                </Popover>
                                </>
                            }
                            query={queryRec}
                            model={DeliveryCargoListModel(props.mode)}
                            action={actions}
                            pageSize={20}
                            scroll={{ x: 1300, y: "65vh" }}
                        />
                        <Modal title={qtyText} visible={isModalVisible} onOk={handleOk}
                            onCancel={handleCancel} width={230} okText="Igen" cancelText="Nem">
                            <InputNumber value={actQty}
                            onInput={
                                (value) => {
                                    value <= maxQty ? setActQty(Number(value)) : setActQty(0);
                                }
                            }
                            onStep={
                                (value) => {
                                    value <= maxQty ? setActQty(value) : setActQty(0);
                                }
                            }

                            min={0} max={maxQty} style={{width: "100%"}}/>

                        </Modal>
                        <Modal title={deleteText} visible={isDeleteVisible} onOk={handleDeleteOk}
                            onCancel={handleDeleteCancel} width={230} okText="Igen" cancelText="Nem">
                        </Modal>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="place"
                            initialValue={1}
                            label={<span style={{fontWeight: "bold", fontSize: "large"}}><Trans>Choose a target</Trans></span>}
                            children={<Radio.Group value={value}>
                                <Space direction="vertical">
                                    <Radio value={1}><Trans>External supplier</Trans></Radio>
                                    <Radio value={2}><Trans>Warehouse</Trans></Radio>
                                </Space>
                            </Radio.Group>
                            }
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="comment"
                            label={<span style={{fontWeight: "bold", fontSize: "large"}}><Trans>Add remark [optional]</Trans></span>}
                            children={<TextArea rows={4}/>}
                        />
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                        children={
                            <>
                                <Button className={'ActionButton'} style={{marginRight: 5}} htmlType="button" type="primary" onClick={() => {
                                    if (props.onClose) {
                                        props.onClose();
                                    }
                                    }
                                }>
                                    <Trans>Back</Trans>
                                </Button>

                                <Button className={'ActionButton'} disabled={!hasCargo} htmlType="button" type="primary" onClick={() => form.submit()}>
                                    <Trans>Save</Trans>
                                </Button>

                            </>
                        }
                        />
                    </Col>
                </Row>
            </Form>
        );

}
export default NewDeliveryForm;
