import React from "react";
import {FC} from "react";
import DtoTable from "../../components/dto-table/dto-table";
import CargoListModel from "../../models/dto/cargo-list-model";
import useSafeState from "../../utils/safe-state";
import {Breadcrumb, Tooltip, Button, message, Popconfirm, Divider} from "antd";
import {Trans} from "@lingui/macro";



import {
    FileOutlined,
    QrcodeOutlined,
    FileAddOutlined, DeleteTwoTone, EditOutlined, EditTwoTone, DownloadOutlined,

} from "@ant-design/icons/lib";

import {PlusOutlined} from "@ant-design/icons/lib";
import Drawer from "../../components/drawer/drawer";
import UploadedFilesDrawer from "../../forms/cargo-form/uploaded-files-drawer";
import UploadFilesDrawer from "../../forms/cargo-form/upload-files-drawer";
import NewCargoDrawer from "../../forms/cargo-form/new-cargo-drawer";
import LabelPrintDrawer from "../../forms/cargo-form/label-print-drawer";
import { AxiosResponse, AxiosError } from 'axios';
import {useAxios} from "../../utils/hooks";
import {antdContext} from "../../utils/antdContext";
import { openNotification } from '../../utils/openNotification';
import ButtonWithIcon from "../../components/buttons/button-with-icon";




const CargoListScreen : FC = () => {

    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "");
    const [loading, setLoading] = useSafeState(false);
    const [xlsLoading, setXlsLoading] = useSafeState(false);
    const [trigger, setTrigger] = useSafeState(false);    
    const uploadedFilesText = <Trans>Cargo uploaded files list</Trans>
    const uploadFilesText = <Trans>Cargo upload files</Trans>
    const labelPrintText = <Trans>Label print</Trans>
    const cargoText = <Trans>New Cargo</Trans>

    const newCargoDrawer = Drawer(
        {
            title: cargoText,
            children:
                <NewCargoDrawer/>,
            customClose: () => setTrigger(!trigger)
        }
    );

    const uploadedFilesDrawer = Drawer(
        {
            title: uploadedFilesText,
            children:
                <UploadedFilesDrawer/>,
            customClose: () => setTrigger(!trigger)
        }
    );


    const labelPrintDrawer = Drawer(
        {
            title: labelPrintText,
            children:
                <LabelPrintDrawer/>,
            customClose: () => setTrigger(!trigger)
        }
    );


    const uploadFilesDrawer = Drawer(
        {
            title: uploadFilesText,
            children:
                <UploadFilesDrawer/>,
            customClose: () => setTrigger(!trigger)
        }
    );

    const deleteRecord = (record: any) => {
        if(axiosInstance.current != null) {
            setLoading(true);
            axiosInstance.current.delete("/resource/cargo/deleterecord?id=" + record.id, {})
                .then(function (response : AxiosResponse<any>) {
                    setLoading(false);
                    message.success(antdContext('Az adott sor sikeresen törölve lett.'));
                    setTrigger(false);
                    setTrigger(!trigger);
                }).catch(function (error :AxiosError) {
                    let errResp = JSON.parse(JSON.stringify(error.response));
                    let errMessage = errResp.data.message;
                    openNotification('error',errMessage);
                    console.log(error)
                    setLoading(false);
            });
        }
    }

    const action =  (record: any) => {

            return (
            <>
                <Tooltip  title={<Trans>View</Trans>}>
                    <EditTwoTone
                        twoToneColor={"#40a9ff"}
                        className={"ActionButtons"} onClick={
                        () => newCargoDrawer.open("Edit", {
                            data: {
                                id: record.id,
                                cargoName: record.cargoName,
                                quantity: record.quantity,
                                uniqueId: record.uniqueId,
                                ownUniqueId: record.ownUniqueId,
                                weight: record.weight,
                                comment: record.comment,
                                item: record.item.itemNo,
                                status: record.status,
                                version: record.version
                            },
                            mode: 'Edit'
                        })}/>

                </Tooltip>
                <Tooltip  title={<Trans>Label print</Trans>}>
                    <QrcodeOutlined
                        disabled={record.status != 'CLOSED' && record.status != 'UIDPRINTED'}
                        className={"ActionButtons"}
                        style={{color: (record.status == 'CLOSED' || record.status == 'UIDPRINTED') ? "#40a9ff" : "gray"}}
                        onClick={() => labelPrintDrawer.open("", {cargoData: record})}/>
                </Tooltip>
                <Tooltip  title={<Trans>Download files</Trans>}>
                    <FileOutlined  className={"ActionButtons"} style={{color: "#40a9ff"}} onClick={()=>uploadedFilesDrawer.open("", {cargoData: record})}/>
                </Tooltip>
                <Tooltip  title={<Trans>Upload files</Trans>}>
                    <FileAddOutlined disabled={
                        record.status != 'CLOSED'
                    } className={"ActionButtons"}
                                     style={{color: "#40a9ff"}} onClick={() => uploadFilesDrawer.open("", {cargoData: record})} />
                </Tooltip>
                <Tooltip  title={<Trans>Remove</Trans>}>
                    <Popconfirm  title={record.status != 'DELIVERED' ? <Trans>Are you sure want to delete record?</Trans>:<Trans>Delivered record cannot be deleted</Trans>}
                            onConfirm={() => record.status != 'DELIVERED' ? deleteRecord(record) : ""}
                            okText={<Trans>Yes</Trans>}
                            cancelText={<Trans>No</Trans>}> 
                        <DeleteTwoTone disabled={record.status != 'DELIVERED'} className={"ActionButtons"} twoToneColor={record.status != 'DELIVERED' ? "red" : "gray"}/>
                    </Popconfirm>

                </Tooltip>

            </>
    )};

    const actions = {
        width: 200,
        render: (text: string, record: any) => action(record),
        title: '',
        fixed: 'right',
        align: 'center'
    };


    const handleDownloadXls = () => {
        setXlsLoading(true);
        if (axiosInstance.current != null) {
            const FileDownload = require('js-file-download');
            let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'hu';
            axiosInstance
                .current({
                    url: process.env.REACT_APP_API_BASE_URL + '/resource/cargo/' + lang + '/xls/',
                    method: 'POST',
                    responseType: 'blob',
                })
                .then(response => {
                    FileDownload(response.data, 'cargo_list.xls');
                })
                .finally(() => setXlsLoading(false));
        }
    };

    const header = (
        <>
            <Button type={"primary"} onClick={()=>newCargoDrawer.open("", {
                mode: 'AddNew'
            })
            } style={{float: "left", marginRight:10}}><PlusOutlined /><span><Trans>Add new cargo</Trans></span>
            </Button>
        </>
    );


    return (<>
    
        <>
        
            <Breadcrumb style={{ marginBottom: 10 }}>
                <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
                <Breadcrumb.Item><Trans id={'Cargo list'}></Trans></Breadcrumb.Item>
            </Breadcrumb>
            <DtoTable
                tableHeader={header}
                model={CargoListModel}
                action={actions}
                apiUrl={"cargo"}
                trigger={trigger}
                allowExport={true}
                allowRefreshButton={true}
                pageSize={100}
                scroll={{ x: 1300, y: "65vh" }}
                />
                {uploadedFilesDrawer.component}
                {labelPrintDrawer.component}
                {uploadFilesDrawer.component}
                {newCargoDrawer.component}
                </>
        
    
    </>
)
}
export default CargoListScreen;
