import React from 'react';
import { notification } from 'antd';
import { antdContext } from '../utils/antdContext';

export function openNotification(status: any, error: any): any;
export function openNotification(status: any, error: any, props: any): any;
export function openNotification(
    status: any,
    error?: any,
    props?: any,
): any {

    if (status === 'error') {

        if (error != null && error.response != null && error.response.data != null && error.response.data.message != null) {
            notification.error({
                message: <b> { antdContext('Hiba') } </b>,
                description: antdContext(error.response.data.message),
                placement: 'topRight',
                duration: 20
            });
        } else if(error != null && error.message != null) {
            notification.error({
                message: <b> { antdContext('Hiba') } </b>,
                description: antdContext(error.message),
                placement: 'topRight',
                duration: 20
            });
        } else if(error != null) {
            notification.error({
                message: <b> { antdContext('Hiba') } </b>,
                description: antdContext(error),
                placement: 'topRight',
                duration: 20
            });
        }
        else {
            notification.error({
                message: <b> { antdContext('Hiba') } </b>,
                description: '',
                placement: 'topRight',
                duration: 20
            });
        }

    } else if (status === 'success') {
        notification.success({
            message: <b> { antdContext('Sikeres mentés') } </b>,
            description: '',
            placement: 'topRight'
        });
        if (props != null && props.onClose) {
            props.onClose();
        }
    } else if (status === 'customSuccess') {
        notification.success({
            message: <b> { antdContext(error) } </b>,
            description: '',
            placement: 'topRight',
        });
        if (props != null && props.onClose) {
            props.onClose();
        }
    } else if (status === 'customError') {
        notification.error({
            message: <b> { antdContext('Hiba') } </b>,
            description: antdContext(error),
            placement: 'topRight',
            duration: 20
        });
    } else {
        notification.error({
            message: <b> { antdContext('Ismeretlen hiba') } </b>,
            description: '',
            placement: 'topRight',
            duration: 20
        });
    }
}

