import {ColumnMain} from "../column-main";
import {Column} from "../column";
import {Trans} from "@lingui/macro";
import React from "react";
import { Tag } from 'antd';

const LocationListModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();
    columns.push({key:'locationCode', width: 70, filter:true, sorter:true,title:<Trans>Location</Trans>});
    columns.push({
        key: 'status',
        width: 100,
        uniqueSelectFilterData: [
            {
                label: <Trans>All</Trans>,
                value: null,
            },
            {
                label: <Trans>Free</Trans>,
                value: 'FREE',
            },
            {
                label: <Trans>Occupied</Trans>,
                value: 'OCCUPIED',
            },
            {
                label: <Trans>Locked-free</Trans>,
                value: 'LOCKED_FREE',
            },
            {
                label: <Trans>Locked-occupied</Trans>,
                value: 'LOCKED_OCCUPIED',
            },
            
        ],
        sorter: true,
        title: <Trans>Status</Trans>,
        render: text => {
            let color = 'gray';
            let transText;
            if (text === 'FREE') {
                color = 'gray';
                transText = <Trans>Free</Trans>;
            } else if (text === 'OCCUPIED') {
                color = 'green';
                transText = <Trans>Occupied</Trans>;
            } else if (text === 'LOCKED_FREE') {
                color = 'blue';
                transText = <Trans>Locked-free</Trans>;
            } else if (text === 'LOCKED_OCCUPIED') {
                color = 'yellow';
                transText = <Trans>Locked-occupied</Trans>;
            } 
            return (
                <Tag color={color} key={text}>
                    {transText}
                </Tag>
            );
        },
    });

    columns.push({key:'updatedDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Updated Date</Trans>});
    columns.push({key:'updatedBy', width: 150, dateFilter:true, sorter:true,title:<Trans>Updated By</Trans>});
    columns.push({key:'createdDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Created Date</Trans>,direction:"desc"});
    columns.push({key:'createdBy', width: 150, dateFilter:true, sorter:true,title:<Trans>Created By</Trans>});


    return{
        columns:columns,
        url:"/resource/location"
    };

}
export default LocationListModel();
