import {ColumnMain} from "../column-main";
import {Column} from "../column";
import {Trans} from "@lingui/macro";
import React from "react";
import { Tag } from 'antd';

const CargoListModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();
    columns.push({key:'ownUniqueId', filter:true, width: 80, sorter:true,title:<Trans>#</Trans>});
    columns.push({key:'cargoName', filter:true, width: 200, sorter:true,title:<Trans>Name</Trans>});
    columns.push({key:'item.itemNo', width: 200, dataIndex: ['item', 'itemNo'], filter:true, sorter:true,title:<Trans>ItemNo</Trans>});
    columns.push({key:'item.manufacturer', width: 200, dataIndex: ['item', 'manufacturer'], filter:true, sorter:true,title:<Trans>ItemNo</Trans>});
    columns.push({key:'quantity', filter:true, width: 200, sorter:true,title:<Trans>Quantity</Trans>});
    columns.push({key:'needToDeliveryQuantity', filter:true, width: 200, sorter:true,title:<Trans>Need To Delivery Quantity</Trans>});
    columns.push({key:'deliveredQuantity', filter:true, width: 200, sorter:true,title:<Trans>Delivered Quantity</Trans>});
    columns.push({key:'weight', filter:true, sorter:true, width: 200, title:<Trans>Weight</Trans>});
    columns.push({key:'comment', filter:true, sorter:true, width: 200, title:<Trans>Comment</Trans>});
    columns.push({
        key: 'status',
        uniqueSelectFilterData: [
            {
                label: <Trans>All</Trans>,
                value: null,
            },
            {
                label: <Trans>Prepared</Trans>,
                value: 'DATARECORDED',
            },
            {
                label: <Trans>Label Printed</Trans>,
                value: 'UIDPRINTED',
            },
            {
                label: <Trans>Stored</Trans>,
                value: 'STORED',
            },
            {
                label: <Trans>Locked</Trans>,
                value: 'CLOSED',
            },
            {
                label: <Trans>Delivered</Trans>,
                value: 'DELIVERED',
            },
            {
                label: <Trans>Releasable</Trans>,
                value: 'RELEASABLE',
            },
        ],
        sorter: true,
        width: 200,
        title: <Trans>Status</Trans>,
        render: text => {
            let color = 'gray';
            let transText;
            if (text === 'DATARECORDED') {
                color = 'cyan';
                transText = <Trans>Prepared</Trans>;
            } else if (text === 'UIDPRINTED') {
                color = 'magenta';
                transText = <Trans>Label Printed</Trans>;
            } else if (text === 'STORED') {
                color = 'purple';
                transText = <Trans>Stored</Trans>;
            } else if (text === 'CLOSED') {
                color = 'red';
                transText = <Trans>Locked</Trans>;
            } else if (text === 'DELIVERED') {
                color = 'green';
                transText = <Trans>Delivered</Trans>;
            } else if (text === 'RELEASABLE') {
                color = 'gold';
                transText = <Trans>Releasable</Trans>;
            } 
            return (
                <Tag color={color} key={text}>
                    {transText}
                </Tag>
            );
        },
    });
    columns.push({key:'location.locationCode', width: 200, dataIndex:['location','locationCode'], filter:true, sorter:true,title:<Trans>Location</Trans>});
    columns.push({key:'updatedDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Updated Date</Trans>});
    columns.push({key:'updatedBy', width: 150, sorter:true,title:<Trans>Updated By</Trans>});
    columns.push({key:'createdDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Created Date</Trans>,direction:"desc"});
    columns.push({key:'createdBy', width: 150, sorter:true,title:<Trans>Created By</Trans>});



    return{
        columns:columns,
        url:"/resource/cargo/get-all-cargos"
    };

}
export default CargoListModel();
