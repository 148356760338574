import useSafeState from '../utils/safe-state';
import * as React from 'react'
import en from '../locales/en/messages.json';
import hu from '../locales/hu/messages.json';
import {BrowserRouter as Router, Link, Switch} from 'react-router-dom';
import {useKeycloak} from '@react-keycloak/web'
import {PrivateRoute} from './utils'
import {Button, Col, Dropdown, Form, Input, Layout, Menu, message, Modal, Row, Tooltip} from 'antd';
import SubMenu from "antd/es/menu/SubMenu";

import LanguageChange from "../components/language-change/language-change";
import {Language} from "../models/language/language";

import {Trans} from "@lingui/macro";

import {i18n} from '@lingui/core'

import "../routes/App.css";
import './App.css';
import 'antd/dist/antd.css';
import "../routes/index.css";
import logo from "../images/head_logo.png"
import huFlag from "../icons/hu.png";
import enFlag from "../icons/en.png";
import {
    DatabaseOutlined, DownloadOutlined,
    HomeOutlined,
    MenuFoldOutlined, MenuUnfoldOutlined, QrcodeOutlined,
    CopyOutlined, SnippetsOutlined
} from "@ant-design/icons";

import {
    MessageOutlined,
    CalendarOutlined,
    AlertOutlined,
    CopyrightOutlined,
    ControlOutlined,
    ReconciliationOutlined,
    LogoutOutlined,
    QuestionCircleOutlined,
    SwapOutlined,
    ArrowUpOutlined,
    FileOutlined, SettingOutlined, DoubleRightOutlined, DoubleLeftOutlined, VideoCameraOutlined
} from '@ant-design/icons/lib';

import {Footer} from "antd/lib/layout/layout";
import LandingScreen from "../screens/landing/landing";
import isAuthorized from "../utils/is-authorized";
import SupportIcon from "../components/icons/support-icon";
import Icon from '@ant-design/icons';
import {useAxios} from "../utils/hooks";
import {useState} from "react";
import {AxiosResponse} from "axios";
import QRCode from "qrcode";
import {antdContext} from "../utils/antdContext";
import CargoListScreen from '../screens/cargo/cargo-list';
import DeliveryOrderListScreen from '../screens/delivery/delivery-order-list';
import LocationListScreen from '../screens/location/location-list';
import UsersListScreen from '../screens/users/users-list';
import ItemsScreen from '../screens/items/items';
import CameraReadingsScreen from '../screens/camerareadings/camerareadings';


const {Header, Sider, Content} = Layout;

export const AppRouter = () => {

    const SupportIco = (props: any) => <Icon component={SupportIcon} {...props} />;

    const {initialized, keycloak} = useKeycloak();
    const myStorage = window.localStorage;
    const axiosInstance = useAxios("")

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [qrCode, setQrCode] = useState<any>();

    const languageModel: Language[] = [
        {
            language: "hu",
            icon: huFlag
        },
        {
            language: "en",
            icon: enFlag
        }
    ];

    const [loggedUser, setLoggedUser] = useSafeState<any>('')
    const [collapsed, setCollapsed] = useSafeState(false)
    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    }


    const showModal = () => {
        setIsModalVisible(true);
    };


    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const [form] = Form.useForm();
    form.resetFields();

    React.useEffect(() => {

        if (keycloak && keycloak.tokenParsed) {

            let obj: any = JSON.parse(JSON.stringify(keycloak.tokenParsed));
            if (keycloak.tokenParsed != null) {
                setLoggedUser(obj.preferred_username);
                localStorage.setItem('username', obj.preferred_username);
                console.log(obj.sub);
                generateQr(obj.sub);
            }
        }

    }, [initialized, keycloak]);

    const openWikiJs = () => {
        console.log(window.location.pathname);
        let pathname =
            ((window.location.pathname == "/" || window.location.pathname == "/landing")
                ? "/home"
                : window.location.pathname).replace( new RegExp("[0-9]+","gm"),"");

        window.open(process.env.REACT_APP_WIKI_URL + pathname, '_blank');
    }

    if (!initialized) {
        return <div>
            <Trans>Loading...</Trans>
        </div>
    }

    const generateQr = async (kcid: string) => {

        if(kcid){
            let at = await QRCode.toDataURL(kcid);
            setQrCode(at);
        }
    }

    const menu = (
        <Menu>
            <Menu.Item
                onClick={() => {
                //console.log(window.location.origin);
                keycloak.redirectUri = window.location.origin;
                keycloak.logout();
                localStorage.clear();
            }}>
                <span style={{
                    fontSize: "small",
                    cursor: "pointer",
                    marginRight: 20
                }}>
                    <span style={{marginRight: 5}}><LogoutOutlined/></span><span><Trans>Logout</Trans></span></span>
            </Menu.Item>
            <Menu.Item>
                <a download={loggedUser} href={qrCode}>
                <span style={{
                    fontSize: "small",
                    cursor: "pointer",
                    marginRight: 20
                }}>
                    <span style={{marginRight: 5}}><QrcodeOutlined /></span><span><Trans>Print Qr</Trans></span></span>
                </a>

            </Menu.Item>
        </Menu>
    );

    return (

        <Router>
            <Layout style={{minHeight: "100vh"}}>
                <Sider trigger={null} collapsible collapsed={collapsed} width={250}>
                    <Menu theme={"dark"} mode="inline" defaultSelectedKeys={['1']}>

                        {isAuthorized(keycloak,["KVJ_GLOBAL_ADMIN", "KVJ_SUPPL_ADMIN", "KVJ_USER", "KVJ_SUPPL_USER"]) ?
                            <Menu.Item key="1" icon={<HomeOutlined/>}>
                                <Link to="/landing"><Trans>Homepage</Trans></Link>
                            </Menu.Item>
                            : <></>
                        }
                        {isAuthorized(keycloak,["KVJ_GLOBAL_ADMIN", "KVJ_SUPPL_ADMIN", "KVJ_USER", "KVJ_SUPPL_USER"]) ?
                                <Menu.Item key="shipmentData" icon={<DoubleRightOutlined />}>
                                    <Link to="/cargolist"><Trans>Incomings</Trans></Link>
                                </Menu.Item>
                            : <></>
                        }
                        {isAuthorized(keycloak,["KVJ_GLOBAL_ADMIN", "KVJ_SUPPL_ADMIN", "KVJ_USER", "KVJ_SUPPL_USER"]) ?
                        
                            <Menu.Item key="deliveryOrder" icon={<DoubleLeftOutlined />}>
                                <Link to="/deliveryorder"><Trans>Deliveries</Trans></Link>
                            </Menu.Item>
                        
                            : <></>
                        }
                        {isAuthorized(keycloak,["KVJ_GLOBAL_ADMIN"]) ?
                            <SubMenu key="SettingsSubMenu" icon={<DatabaseOutlined/>} title={<Trans>Settings</Trans>}>
                                <Menu.Item key="item">
                                    <Link to="/items"><Trans>Items</Trans></Link>
                                </Menu.Item>
                                <Menu.Item key="location">
                                    <Link to="/location"><Trans>Location</Trans></Link>
                                </Menu.Item>
                                <Menu.Item key="users">
                                    <Link to="/users"><Trans>Users management</Trans></Link>
                                </Menu.Item>
                            </SubMenu>
                            : <></>
                        }
                        {isAuthorized(keycloak,["KVJ_GLOBAL_ADMIN", "KVJ_SUPPL_ADMIN", "KVJ_USER", "KVJ_SUPPL_USER"]) ?

                            <Menu.Item key="camerareadings" icon={<VideoCameraOutlined />}>
                                <Link to="/camerareadings"><Trans>CameraReadings</Trans></Link>
                            </Menu.Item>

                            : <></>
                        }
                    </Menu>
                </Sider>
                <Layout className="site-layout ">
                    <Header className="site-layout-background" style={{textAlign: "center", padding: 0}}>
                        <Row>
                            <Col span={10}>
                                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                    className: 'trigger',
                                    onClick: toggleCollapse,
                                    style: {float: 'left'}
                                })}
                            </Col>
                            <Col span={4}>
                                    <span style={{textAlign: "center", width: 125}}>
                                        <img src={logo}/>
                                    </span>
                            </Col>
                            <Col span={10}>
                                    <span style={{float: 'right'}}>


                                        <Tooltip placement="bottom" title={<Trans>Help</Trans>}>
                                            <span style={{verticalAlign: 'middle'}}>
                                                <QuestionCircleOutlined style={{marginRight: 20, fontSize: 'x-large'}}
                                                                        onClick={() => openWikiJs()}/>
                                            </span>
                                        </Tooltip>

                                        <Tooltip placement="bottom" title={<Trans>Support</Trans>}>
                                            <span style={{verticalAlign: 'middle'}}>
                                                <a style={{textDecoration: "none", color: "black"}} href={"mailto:support@logicloud.hu"}><SupportIco style={{ marginRight: 20, fontSize: 'x-large' }} /></a>
                                            </span>
                                        </Tooltip>

                                        <span
                                            className={process.env.REACT_APP_ENVIRONMENT}>{process.env.REACT_APP_ENVIRONMENT}</span>

                                        <LanguageChange
                                            handleLanguageChange={(e) => {
                                                i18n.activate(e.key + '');
                                                myStorage.setItem('language', e.key);
                                            }}
                                            activeLanguage={localStorage.getItem('language') ? localStorage.getItem('language') + '' : 'hu'}
                                            languages={languageModel}/>

                                        <Dropdown overlay={menu} placement="bottomLeft">
                                            <Button style={{border: 0}}><span
                                                style={{fontSize: 'large'}}>{loggedUser} </span></Button>
                                        </Dropdown>
                                    </span>
                            </Col>
                        </Row>
                    </Header>
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '12px 8px',
                            padding: 20
                        }}
                    >
                        <Switch>
                            <PrivateRoute roles={["KVJ_GLOBAL_ADMIN", "KVJ_SUPPL_ADMIN", "KVJ_USER", "KVJ_SUPPL_USER"]} exact path="/" component={LandingScreen}/>
                            <PrivateRoute roles={["KVJ_GLOBAL_ADMIN", "KVJ_SUPPL_ADMIN", "KVJ_USER", "KVJ_SUPPL_USER"]} exact path="/landing" component={LandingScreen}/>
                            <PrivateRoute roles={["KVJ_GLOBAL_ADMIN", "KVJ_SUPPL_ADMIN", "KVJ_USER", "KVJ_SUPPL_USER"]} exact path="/cargolist" component={CargoListScreen}/>
                            <PrivateRoute roles={["KVJ_GLOBAL_ADMIN", "KVJ_SUPPL_ADMIN", "KVJ_USER", "KVJ_SUPPL_USER"]} exact path="/deliveryorder" component={DeliveryOrderListScreen}/>
                            <PrivateRoute roles={["KVJ_GLOBAL_ADMIN", "KVJ_SUPPL_ADMIN", "KVJ_USER", "KVJ_SUPPL_USER"]} exact path="/items" component={ItemsScreen}/>
                            <PrivateRoute roles={["KVJ_GLOBAL_ADMIN", "KVJ_SUPPL_ADMIN", "KVJ_USER", "KVJ_SUPPL_USER"]} exact path="/camerareadings" component={CameraReadingsScreen}/>
                            <PrivateRoute roles={["KVJ_GLOBAL_ADMIN"]} exact path="/location" component={LocationListScreen}/>
                            <PrivateRoute roles={["KVJ_GLOBAL_ADMIN"]} exact path="/users" component={UsersListScreen}/>
                        </Switch>
                    </Content>
                    <Footer style={{ textAlign: "center", fontSize: 10, paddingTop: 0, paddingRight: 10, paddingLeft: 10, paddingBottom: 9}}>
                        <Row>
                            <Col span={8}></Col>
                            <Col span={8}>
                                <CopyrightOutlined />{' '}
                                <span>
                                    {' '}
                                    2024 Copyright  KVJ. | Created by{' '}
                                    <a href="https://www.logicloud.hu" target="_blank" >LogiCloud Kft.</a>
                                </span>
                            </Col>
                            <Col span={8} style={{ textAlign: 'right' }}>
                                {process.env.REACT_APP_VERSION + '-SNAPSHOT'}
                            </Col>
                        </Row>
                    </Footer>
                </Layout>
            </Layout>
        </Router>
    )
}
