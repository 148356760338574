import useSafeState from '../../utils/safe-state';
import React, { ReactElement } from 'react';

interface Interface {
    icon: ReactElement;
    text: ReactElement;
}

const ButtonWithIcon = (props: Interface) => {

    const createIcon = (): React.ReactNode => {
        return React.cloneElement(props.icon, {});
    };

    const createText = (): React.ReactNode => {
        return React.cloneElement(props.text, {});
    };

    return (
        <>
            {createIcon()}
            <span>{createText()}</span>
        </>
    );
};

export default ButtonWithIcon;
