import React, {useState} from "react";
import {SelectType} from "../interfaces/SelectType";
import {Trans} from "@lingui/macro";
import useSafeState from "./safe-state";

type optionalType = {
    key?: number;
    label: any;
    value: string;
};

export enum  status {'Szabad' , 'Foglalt' , 'Zárolt szabad', 'Zárolt foglalt'}

function getEnumArray(name: string) {
    let enumArray: Array<SelectType> = [];
    let enumKeys: any[];

    switch (name) {
        case 'status':
            enumKeys = Object.keys(status).filter((v) => isNaN(Number(v)));
            break;
         
        default:
            enumKeys = [];
        break;


    }
    enumKeys.forEach((key) => {
        enumArray.push(
        {
            label: key,
            value: key
        })
    })

    return enumArray;
}



export default getEnumArray;


