import React from "react";
import {FC} from "react";
import DtoTable from "../../components/dto-table/dto-table";

import useSafeState from "../../utils/safe-state";
import {Breadcrumb, Tooltip, Button, Popconfirm, message} from "antd";
import {Trans} from "@lingui/macro";
import {useAxios} from "../../utils/hooks";
import { AxiosResponse, AxiosError } from 'axios';
import {antdContext} from "../../utils/antdContext";
import { openNotification } from '../../utils/openNotification';
import Drawer from "../../components/drawer/drawer";

import {
    DeleteTwoTone,
    EditOutlined, QrcodeOutlined,
} from "@ant-design/icons/lib";

import {PlusOutlined} from "@ant-design/icons/lib";

import NewLocationDrawer from "../../forms/location-form/new-location-drawer";
import LocationListModel from "../../models/dto/location-list-model";


const LocationListScreen : FC = () => {

    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "");
    const [loading, setLoading] = useSafeState(false);
    const [trigger, setTrigger] = useSafeState(false);
    

    const locationText = <Trans>New Location</Trans>
    

    const newLocationDrawer = Drawer(
        {
            title: locationText,
            children:
                <NewLocationDrawer/>,
            customClose: () => setTrigger(!trigger)
        }
    );

    const deleteRecord = (deleteid: any) => {
        
        if(axiosInstance.current != null) {
            setLoading(true);
            axiosInstance.current.delete("/resource/location/deleterecord/?id=" + deleteid, {})
                .then(function (response : AxiosResponse<any>) {
                    setLoading(false);
                    message.success(antdContext('Az adott sor sikeresen törölve lett.'));
                    setTrigger(false);
                    setTrigger(!trigger);
                }).catch(function (error :AxiosError) {
                    let errResp = JSON.parse(JSON.stringify(error.response));
                    let errMessage = errResp.data.message;
                    openNotification('error',errMessage);
                    console.log(error)
                    setLoading(false);
            });
        }
    }

    const downloadQr = (id: any) => {

        if(axiosInstance.current != null){
            const FileDownload = require('js-file-download');

            axiosInstance.current({
                url: process.env.REACT_APP_API_BASE_URL + '/resource/location/download-qr-by-id?id=' + id,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                FileDownload(response.data, 'location_' + id +'_.pdf');
            }).catch(function (error :AxiosError) {
                openNotification('error',error);

            });
        }
    }


    const action =  (record: any) => {

            return (            

            <>
           
               <Tooltip  title={<Trans>Edit</Trans>}>
                   <EditOutlined  className={"ActionButtons"} style={{color: "#40a9ff"}} onClick={()=>newLocationDrawer.open("", {
                            locationId: record.id, mode: 'Edit'
                      })
              }/>

               <Tooltip  title={<Trans>Label print</Trans>}>
                   <QrcodeOutlined
                       className={"ActionButtons"}
                       style={{color: "#40a9ff"}}
                       onClick={()=> downloadQr(record.id)}/>
               </Tooltip>

               <Tooltip  title={<Trans>Remove</Trans>}>
                <Popconfirm  title={record.status == ('FREE' || 'LOCKED_FREE') ? <Trans>Are you sure want to delete record?</Trans>:<Trans>Record cannot be deleted</Trans>}
                            onConfirm={() => record.status == ('FREE' || 'LOCKED_FREE') ? deleteRecord(record.id) : ""}
                            okText={<Trans>Yes</Trans>}
                            cancelText={<Trans>No</Trans>}> 
                    <DeleteTwoTone disabled={record.status == ('FREE' || 'LOCKED_FREE')} className={"ActionButtons"} twoToneColor={record.status == ('FREE' || 'LOCKED_FREE') ? "red" : "gray"}/>
                </Popconfirm>
               </Tooltip>

           </Tooltip>          
                
                
                                
                

                </>
    )};

    const actions = {
        width: 110,
        render: (text: string, record: any) => action(record),
        title: '',
        fixed: 'right',
        align: 'center'
    };

    


    return (<>
    
        
            <Breadcrumb style={{ marginBottom: 10 }}>
                <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
                <Breadcrumb.Item><Trans id={'Location list'}></Trans></Breadcrumb.Item>
            </Breadcrumb>
            <DtoTable
                tableHeader={<Button type={"primary"} onClick={()=>newLocationDrawer.open("", {
                 mode: 'AddNew'
              })
          } style={{float: "left", marginRight:10}}><PlusOutlined /><span><Trans>Add new location</Trans></span></Button>}
                model={LocationListModel}
                action={actions}
                trigger={trigger}
                allowRefreshButton={true}
                pageSize={100}
                scroll={{ x: 1300, y: "65vh" }}
                />
                {newLocationDrawer.component}
                </>
        
    
    
    );
}
export default LocationListScreen;
