
import React, { useEffect} from 'react';
import {Form, Row, Col, Input, Button, Card} from 'antd';
import {useAxios} from '../../utils/hooks';
import {AxiosError} from "axios";
import {Trans} from "@lingui/macro";



import { openNotification } from '../../utils/openNotification';


interface Interface {
    data?: any;
    onClose?: () => void;
}

const LabelPrintDrawer  = (props: Interface) => {

    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "")
    
    const [form] = Form.useForm();
    
    useEffect(() => {

        if (props.data.cargoData.ownUniqueId != undefined) {
            form.setFieldsValue({"uniqueid":props.data.cargoData.ownUniqueId});
        }
        
    },[])

    const handleFinish = () => {
            
            if(axiosInstance.current != null){
                const FileDownload = require('js-file-download');
                let id = props.data.cargoData.id;
                axiosInstance.current({
                    url: process.env.REACT_APP_API_BASE_URL + '/resource/cargo/cargoqrtopdf?id=' + id + "&ownuniqueid=" + form.getFieldValue(["uniqueid"]),
                    method: 'GET',
                    responseType: 'blob'
                }).then((response) => {
                    FileDownload(response.data, 'label_' + id +'_.pdf');
                }).catch(function (error :AxiosError) {
                    openNotification('error',error);
                
            });
            }
    }


    return(
        
        <Form id='label-print'  form={form} layout="vertical" onFinish={() => handleFinish()}>
            {
                <>
                    <Row gutter={24}>
                        <Col span={12}>
                                <Form.Item
                                    name="uniqueid"
                                    label={<Trans>Unique identifier</Trans>}
                                    children={<Input width={50}/>}
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter the unique identifier</Trans>
                                    }]}
                                />
                        </Col>
                    </Row>
                    <Form.Item
                        children={
                            <>
                                <Button htmlType="button" type="primary" onClick={() => form.submit()}>
                                    <Trans>Print</Trans>
                                </Button>
                            </>
                        }
                    />
                </>
            }
        </Form>
    );

}
export default LabelPrintDrawer;
