import React, {ReactNode, useEffect} from "react";
import {Button, Dropdown, Menu, Space, Spin} from "antd";
import {Trans} from "@lingui/macro";
import {DownOutlined,LoadingOutlined} from "@ant-design/icons";
import {CustomDropDownType} from "../../interfaces/CustomDropDownType";
import "./custom-dropdown.css";
import {DownloadOutlined} from "@ant-design/icons/lib";

interface Interface {
    menuItemArray: CustomDropDownType[];
    buttonTitle: string;
    handleMenuClick: (event : any) => void;
    isLoading: boolean;
}

const CustomDropDown = (props : Interface) => {

    const menuList : ReactNode[] = [];

    useEffect(() => {
        props.menuItemArray.forEach(value => menuItem(value));
    },[props.menuItemArray])


    const menuItem =  (c : CustomDropDownType) =>
    {
        menuList.push(<Menu.Item key={c.key}  icon={c.icon} onClick={ event => props.handleMenuClick(event)}>
            {c.title}
        </Menu.Item>);
    }

    const menu = (
        <Menu>
            {
                menuList
            }
        </Menu>
    );


    return <Space wrap style={{float: "right"}}>
                 <Spin spinning={props.isLoading} indicator={<LoadingOutlined/>}>
                    <Dropdown overlay={menu} >
                        <Button>
                            <DownloadOutlined />
                            <span><Trans id={props.buttonTitle}/></span>
                        </Button>
                    </Dropdown>
                 </Spin>
                </Space>;

};
export  default CustomDropDown;
