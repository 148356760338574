import useSafeState from '../../utils/safe-state';
import React, {useEffect, useState, useRef} from "react";
import {Form, Row, Col, Input, Button, message, Select, Card, Spin} from 'antd';
import {Trans} from "@lingui/macro";
import {useAxios} from "../../utils/hooks";
import { AxiosResponse, AxiosError } from 'axios';

import getEnumArray from "../../utils/enums";
import {antdContext} from "../../utils/antdContext";
import { InputNumber } from 'antd';
import type { Grid } from 'antd';
import LocationListScreen from '../../screens/location/location-list';
import {SelectType} from "#/interfaces/SelectType";
import { openNotification } from '../../utils/openNotification';



interface Interface{
    locationId?: any;
    mode?: "View" | "AddNew" | "Edit";
}

const NewLocationForm = (props : Interface) => {

    const [statusOptions,setStatusOptions] = useSafeState<any>();

    const [form] = Form.useForm();
    
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "");

    const [loading, setLoading] = useSafeState(false);
    
    const [isView, setIsView] = useSafeState<boolean>();
    const [isEdit, setIsEdit] = useSafeState<boolean>();

    const [locationId, setLocationId] = useSafeState<number>();


    const viewMode = () => {
        if(axiosInstance.current != null) {
            
            const resp = axiosInstance.current.get("/resource/location/" + locationId, {                    
                }
                ).then(function (response : AxiosResponse<any>)
                {
                    let location = JSON.parse(JSON.stringify(response.data));
                    let status:any;
                    switch (location.status) {
                        case 'FREE':
                            status = 'Szabad';
                            break;
                        case 'OCCUPIED':
                            status = 'Foglalt';
                            break;
                        case 'LOCKED_FREE':
                            status = 'Zárolt szabad';
                            break;
                        case 'LOCKED_OCCUPIED':
                            status = 'Zárolt foglalt';
                            break;
                        default:
                            break;    
                    }
                    form.setFieldsValue({"status":status});
                    form.setFieldsValue({"locationcode":location.locationCode});
                    

                }).catch(function (error :any) {
                message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                console.log(error)
                setLoading(false);
            });

            
        }
    }

    useEffect(() => {
        let params:any = JSON.parse(JSON.stringify(props));
        setLocationId(params.data.locationId);
        let initMode:any = params.data == undefined ? undefined : params.data.mode;
        setStatusOptions(getEnumArray('status'));
        
        setIsView(initMode == 'View' ? true : false);
        setIsEdit(initMode == 'Edit' ? true : false);
        
    }, []);


    useEffect(() => {
        
        if (isView || isEdit) {
            viewMode();
        }
    },[isView,isEdit]

    );

    const handleFinish = () => {
        

        if(axiosInstance.current != null) {
            setLoading(true);                    
                    
            let id:any = locationId == undefined ? "" : "&id=" + locationId;
            let formStatus:any = form.getFieldValue(["status"]);
            let status:any;
            switch (formStatus) {
                case 'Szabad':
                    status = 'FREE';
                    break;
                case 'Foglalt':
                    status = 'OCCUPIED';
                    break;
                case 'Zárolt szabad':
                    status = 'LOCKED_FREE';
                    break;
                case 'Zárolt foglalt':
                    status = 'LOCKED_OCCUPIED';
                    break;
                default:
                    break;    
            }

            const resp = axiosInstance.current.put("/resource/location/saveorupdate?locationCode=" + form.getFieldValue(["locationcode"])
                +"&status="+status
                +id
                ,
                {}
                ).then(function (response : AxiosResponse<any>)
                {      
                    setLoading(false);
                    openNotification('success','');
                
                }).catch(function (error :AxiosError) {
                    let errResp = JSON.parse(JSON.stringify(error.response));
                    let errMessage = errResp.data.message;
                    openNotification('error',errMessage);
                setLoading(false);
            });

        }
    }


    return(
                <Form id="newlocation"  layout='vertical' form={form}  onFinish={() => handleFinish()}>
                <Spin spinning={loading}></Spin>    
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="locationcode"
                                label={<Trans>Location Code</Trans>}
                                children={<Input disabled={isView}/>}
                                rules={[{
                                    type:"string",
                                    required: true,
                                    message: <Trans>Please enter the location code</Trans>
                                }]}
                            />
                        </Col>
                    </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="status"
                            label={<Trans>Status</Trans>}
                            children={
                                <Select disabled={isView}
                                        notFoundContent={<Trans id={'No data'}/>}
                                        style={{ width: '100%' }}
                                        placeholder={<Trans id={'Status'}></Trans>}
                                        options={statusOptions}
                                />
                            }
                            rules={[{
                                type:"string",
                                required: true,
                                message: <Trans>Please enter the status</Trans>
                            }]}
                        />
                    </Col>
                </Row>
                <Form.Item
                    children={
                        <>
                            <Button htmlType="button" type="primary" onClick={() => form.submit()}>
                                <Trans>Save</Trans>
                            </Button>
                        </>
                    }
                />
            </Form>
    );

}
export default NewLocationForm;
