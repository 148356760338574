import useSafeState from "../../utils/safe-state";
import {Button, Col, Form, Input, InputNumber, message, Row, Select, Spin, Tooltip} from "antd";
import {useAxios} from "../../utils/hooks";
import {AxiosError, AxiosResponse} from "axios";
import {antdContext} from "../../utils/antdContext";
import React, {useEffect} from "react";
import {openNotification} from "../../utils/openNotification";
import {Trans} from "@lingui/macro";
import Dragger from "antd/es/upload/Dragger";
import {InboxOutlined, InfoCircleTwoTone} from "@ant-design/icons/lib";
import {ItemModel} from "../../models/items/item"

interface Interface{
    data?: ItemModel;
    mode?: 'Edit' | 'Add new' | 'Upload File';
}

const ItemDrawer = (props: Interface) => {

    const [statusOptions, setStatusOptions] = useSafeState<any>();

    const data = props.data || {};
    const [form] = Form.useForm();

    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "");

    const [fileList, setFileList] = useSafeState<Array<any>>([]);
    const [loading, setLoading] = useSafeState(false);

    const [isView, setIsView] = useSafeState<boolean>();
    const [isEdit, setIsEdit] = useSafeState<boolean>();

    const [locationId, setLocationId] = useSafeState<number>();
    const config = {headers: {'Content-Type': 'application/json'}};


    const viewMode = () => {
        if (axiosInstance.current != null) {

            const resp = axiosInstance.current.get("/resource/location/" + locationId, {}
            ).then(function (response: AxiosResponse<any>) {
                let location = JSON.parse(JSON.stringify(response.data));
                let status: any;
                form.setFieldsValue({"status": status});
                form.setFieldsValue({"locationcode": location.locationCode});


            }).catch(function (error: any) {
                message.error(antdContext('Hiba a lekérdezés közben')).then(r => {
                    console.log(r)
                });
                console.log(error)
                setLoading(false);
            });


        }
    }

    const uploadProps = {
        name: 'pic',
        action: process.env.REACT_APP_API_BASE_URL + '/resource/device-types/upload-csv',
        multiple: false,
        onChange(info: any) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log('uploading', info.file, info.fileList);
            }
            if (status === 'done') {
                openNotification('customSuccess', `${info.file.name} "file uploaded successfully."`);
            } else if (status === 'error') {
                openNotification('customError', `${info.file.name} "file upload failed."`);
            }
        },
        beforeUpload(file: any) {
            let fileList2: Array<any> = fileList;
            fileList2.push(file);
            setFileList(fileList2);
            return false;
        },
    };

    const handleUploadCsv = () => {
        if (axiosInstance.current != null) {
            setLoading(true);
            const formData = new FormData();
            fileList.forEach(file => {
                formData.append('files', file);
            });

            axiosInstance.current
                .post('/resource/items/upload-csv', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                .then(() => {
                    openNotification('success', '', props);
                    setLoading(false);
                })
                .catch(function (error) {
                    openNotification('error', error, props);
                    setLoading(false);
                });
        }
    };

    const handleEdit = () => {
        if (axiosInstance.current != null) {
            setLoading(true);
            axiosInstance.current
                .put(
                    ('/resource/items/id?id='
                        + encodeURIComponent(form.getFieldValue(['itemNo']))),
                    {
                        itemNo: form.getFieldValue(['itemNo']),
                        manufacturer: form.getFieldValue(['manufacturer']),
                        quality: form.getFieldValue(['quality']),
                        sizeX: form.getFieldValue(['sizeX']),
                        sizeY: form.getFieldValue(['sizeY']),
                        normWeight: form.getFieldValue(['normWeight']),
                        version: form.getFieldValue(['version'])
                    },
                    config,
                )
                .then(() => {
                    openNotification('success', '');
                })
                .catch(function (error: any) {
                    openNotification('error', error, props);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };


    useEffect(() => {

        form.resetFields();

        if (props.mode === 'Edit') {
            let fieldsValues = [];
            for (const [key, value] of Object.entries(data)) {
                fieldsValues.push({ name: key, value: value });
            }
            form.setFields(fieldsValues);
        }
    }, []);


    useEffect(() => {

            if (isView || isEdit) {
                viewMode();
            }
        }, [isView, isEdit]
    );

    const handleFinish = () => {


        if (axiosInstance.current != null) {
            setLoading(true);

            const obj = {
                itemNo: form.getFieldValue(["itemNo"]),
                manufacturer: form.getFieldValue(["manufacturer"]),
                quality: form.getFieldValue(["quality"]),
                sizeX: form.getFieldValue(["sizeX"]),
                sizeY: form.getFieldValue(["sizeY"]),
                normWeight: form.getFieldValue(["normWeight"])
            };

            axiosInstance.current.post("/resource/items", obj, config)
            .then(function (response: AxiosResponse<any>) {
                setLoading(false);
                openNotification('success', '');

            }).catch(function (error: AxiosError) {
                let errResp = JSON.parse(JSON.stringify(error.response));
                let errMessage = errResp.data.message;
                openNotification('error', errMessage);
                setLoading(false);
            });

        }
    }

    const onReset = () => {
        form.resetFields();
    };



    return (
        <Form
            id="newItem"
            layout='vertical'
            form={form}
            onFinish={() => (props.mode === 'Edit' ? handleEdit() : handleFinish())}
        >
            {props.mode === 'Edit' || props.mode === 'Add new' ? (
            <>
            <Spin spinning={loading}></Spin>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        name="itemNo"
                        label={<Trans>Item No</Trans>}
                        children={<Input disabled={isView}/>}
                        rules={[{
                            type: "string",
                            required: true,
                            message: <Trans>Please enter the item no</Trans>
                        }]}
                    />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        name="manufacturer"
                        label={<Trans>Manufacturer</Trans>}
                        children={<Input disabled={isView}/>}
                        rules={[{
                            type: "string",
                            required: true,
                            message: <Trans>Please enter the item no</Trans>
                        }]}
                    />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name="quality"
                        label={<Trans>Quality</Trans>}
                        children={<Input disabled={isView}/>}
                        rules={[{
                            type: "string",
                            required: true,
                            message: <Trans>Please enter the item no</Trans>
                        }]}
                    />
                </Col>
                <Col span={6}>
                    <Form.Item
                        name="sizeX"
                        label={<Trans>Size X</Trans>}
                        children={<InputNumber disabled={isView}/>}
                        rules={[{
                            type: "number",
                            required: true,
                            message: <Trans>Please enter the size x</Trans>
                        }]}
                    />
                </Col>
                <Col span={6}>
                    <Form.Item
                        name="sizeY"
                        label={<Trans>Size Y</Trans>}
                        children={<InputNumber disabled={isView}/>}
                        rules={[{
                            type: "number",
                            required: true,
                            message: <Trans>Please enter the size y</Trans>
                        }]}
                    />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        name="normWeight"
                        label={<Trans>Norm Weight</Trans>}
                        children={<InputNumber disabled={isView}/>}
                        rules={[{
                            type: "number",
                            required: true,
                            message: <Trans>Please enter the norm weight</Trans>
                        }]}
                    />
                </Col>
            </Row>
            </>
            ) : (
                <>
                    <Form.Item
                        name="fenykep"
                        children={
                            <Dragger {...uploadProps}>
                                <div></div>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                    <Trans>Click or drag file to this area to upload</Trans>
                                </p>
                                <p className="ant-upload-hint">
                                    <Trans>
                                        Support for a single upload. Strictly prohibit from uploading company data or
                                        other band files
                                    </Trans>
                                </p>
                            </Dragger>
                        }
                    />
                    <br />
                </>
            )}

            {props.mode === 'Edit' ? (
                <Button htmlType="button" type="primary" onClick={() => form.submit()}>
                    <Trans>Save</Trans>
                </Button>
            ) : props.mode === 'Add new' ? (
                <>
                    <Button htmlType="button" style={{ marginRight: 8 }} onClick={() => onReset()}>
                        <Trans>Vissza</Trans>
                    </Button>
                    <Button
                        loading={loading}
                        htmlType="button"
                        type="primary"
                        style={{ marginRight: 8 }}
                        onClick={() => form.submit()}
                    >
                        <Trans>Mentés</Trans>
                    </Button>
                </>
            ) : (
                <Button loading={loading} htmlType="button" type="primary" onClick={() => handleUploadCsv()}>
                    <Trans>Feltöltés</Trans>
                </Button>
            )}
        </Form>
    );

}
export default ItemDrawer;
