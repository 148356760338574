import {ColumnMain} from "#/models/column-main";
import {Column} from "#/models/column";
import {Trans} from "@lingui/macro";
import {Tag} from "antd";
import React from "react";

const ItemModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();
    columns.push({key:'itemNo', filter:true, sorter:true,title:<Trans>ItemNo</Trans>});
    columns.push({key:'manufacturer', filter:true, sorter:true,title:<Trans>Manufacturer</Trans>});
    columns.push({key:'quality', filter:true, sorter:true,title:<Trans>Quality</Trans>});
    columns.push({key:'normWeight', filter:true, sorter:true,title:<Trans>Norm Weight</Trans>});
    columns.push({key:'sizeX', filter:true, sorter:true,title:<Trans>Size X</Trans>});
    columns.push({key:'sizeY', filter:true, sorter:true,title:<Trans>Size Y</Trans>});
    columns.push({key:'updatedDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Updated Date</Trans>});
    columns.push({key:'updatedBy', width: 150, sorter:true,title:<Trans>Updated By</Trans>});
    columns.push({key:'createdDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Created Date</Trans>,direction:"desc"});
    columns.push({key:'createdBy', width: 150, sorter:true,title:<Trans>Created By</Trans>});



    return{
        columns:columns,
        url:"/resource/items"
    };

}
export default ItemModel();
