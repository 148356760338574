import {ColumnMain} from "#/models/column-main";
import {Column} from "#/models/column";
import {Trans} from "@lingui/macro";
import {Tag} from "antd";
import React from "react";

const CameraReadingModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();
    columns.push({key:'id', width: 80, filter:true, sorter:true,title:<Trans>#</Trans>});
    columns.push({key:'data', width: 250, filter:true, sorter:true,title:<Trans>Data</Trans>});
    columns.push({
        key: 'result',
        uniqueSelectFilterData: [
            {
                label: <Trans>All</Trans>,
                value: null,
            },
            {
                label: <Trans>OK_</Trans>,
                value: 'OK',
            },
            {
                label: <Trans>NOK_WRONG_ITEM_TYPE</Trans>,
                value: 'NOK_WRONG_ITEM_TYPE',
            },
            {
                label: <Trans>NOK_WRONG_BOSCH_CODE</Trans>,
                value: 'NOK_WRONG_BOSCH_CODE',
            },
            {
                label: <Trans>NOK_BOSCH_CODE_IS_ALREADY_EXIST</Trans>,
                value: 'NOK_BOSCH_CODE_IS_ALREADY_EXIST',
            },
            {
                label: <Trans>NOK_P1_X</Trans>,
                value: 'NOK_P1_X',
            },
            {
                label: <Trans>NOK_P1_Y</Trans>,
                value: 'NOK_P1_Y',
            },
            {
                label: <Trans>NOK_P2_X</Trans>,
                value: 'NOK_P2_X',
            },
            {
                label: <Trans>NOK_P2_Y</Trans>,
                value: 'NOK_P2_Y',
            },
            {
                label: <Trans>NOK_E2_X</Trans>,
                value: 'NOK_E2_X',
            },
            {
                label: <Trans>NOK_BS1_X</Trans>,
                value: 'NOK_BS1_X',
            },
            {
                label: <Trans>NOK_E1_A</Trans>,
                value: 'NOK_E1_A',
            },
            {
                label: <Trans>NOK_BS2_Y</Trans>,
                value: 'NOK_BS2_Y',
            }
        ],
        sorter: true,
        width: 250,
        title: <Trans>Result</Trans>,
        render: text => {
            let color = 'gray';
            let transText;
            if (text === 'NOK_BOSCH_CODE_IS_ALREADY_EXIST') {
                color = 'red';
                transText = <Trans>NOK_BOSCH_CODE_IS_ALREADY_EXIST</Trans>;
            } else if (text === 'NOK_P1_X') {
                color = 'red';
                transText = <Trans>NOK_P1_X</Trans>;
            } else if (text === 'NOK_P1_Y') {
                color = 'red';
                transText = <Trans>NOK_P1_Y</Trans>;
            } else if (text === 'NOK_P2_X') {
                color = 'red';
                transText = <Trans>NOK_P2_X</Trans>;
            } else if (text === 'NOK_P2_Y') {
                color = 'red';
                transText = <Trans>NOK_P2_Y</Trans>;
            } else if (text === 'NOK_E2_X') {
                color = 'red';
                transText = <Trans>NOK_E2_X</Trans>;
            } else if (text === 'NOK_BS1_X') {
                color = 'red';
                transText = <Trans>NOK_BS1_X</Trans>;
            } else if (text === 'NOK_E1_A') {
                color = 'red';
                transText = <Trans>NOK_E1_A</Trans>;
            } else if (text === 'NOK_BS2_Y') {
                color = 'red';
                transText = <Trans>NOK_BS2_Y</Trans>;
            } else if (text === 'OK') {
                color = 'green';
                transText = <Trans>OK_</Trans>;
            } else if (text === 'NOK_WRONG_BOSCH_CODE') {
                color = 'red';
                transText = <Trans>NOK_WRONG_BOSCH_CODE</Trans>;
            } else if (text === 'NOK_WRONG_ITEM_TYPE') {
                color = 'red';
                transText = <Trans>NOK_WRONG_ITEM_TYPE</Trans>;
            }
            return (
                <Tag color={color} key={text}>
                    {transText}
                </Tag>
            );
        },
    });


    columns.push({
        key: 'piMode',
        uniqueSelectFilterData: [
            {
                label: <Trans>All</Trans>,
                value: null,
            },
            {
                label: <Trans>READING_MODE</Trans>,
                value: 'READING_MODE',
            },
            {
                label: <Trans>SETTINGS_MODE</Trans>,
                value: 'SETTINGS_MODE',
            },
            {
                label: <Trans>CONTROL_MODE</Trans>,
                value: 'CONTROL_MODE',
            }
        ],
        sorter: true,
        width: 150,
        title: <Trans>Pi Mode</Trans>,
        render: text => {
            let color = 'gray';
            let transText;
            if (text === 'READING_MODE') {
                color = 'green';
                transText = <Trans>READING_MODE</Trans>;
            } else if (text === 'SETTINGS_MODE') {
                color = 'orange';
                transText = <Trans>SETTINGS_MODE</Trans>;
            } else if (text === 'CONTROL_MODE') {
                color = 'blue';
                transText = <Trans>CONTROL_MODE</Trans>;
            }
            return (
                <Tag color={color} key={text}>
                    {transText}
                </Tag>
            );
        },
    });


    columns.push({key:'updatedDate', width: 150, dateTimeFilter:true, sorter:true,title:<Trans>Updated Date</Trans>});
    columns.push({key:'updatedBy', width: 150, sorter:true,title:<Trans>Updated By</Trans>});
    columns.push({key:'createdDate', width: 150, dateTimeFilter:true, sorter:true,title:<Trans>Created Date</Trans>,direction:"desc"});
    columns.push({key:'createdBy', width: 150, sorter:true,title:<Trans>Created By</Trans>});


    return{
        columns:columns,
        url:"/resource/camerareadings"
    };

}
export default CameraReadingModel();
