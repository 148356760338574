import React, {FC} from "react";
import {useAxios} from "../../utils/hooks";
import useSafeState from "../../utils/safe-state";
import {Trans} from "@lingui/macro";
import Drawer from "../../components/drawer/drawer";
import ItemDrawer from "../../forms/item-form/item-form";

import {AxiosError, AxiosResponse} from "axios";
import {Breadcrumb, Button, Divider, message, Popconfirm, Tooltip} from "antd";
import {antdContext} from "../../utils/antdContext";
import {openNotification} from "../../utils/openNotification";
import {
    DeleteTwoTone,
    EditOutlined,
    PlusOutlined,
    QrcodeOutlined, UploadOutlined
} from "@ant-design/icons/lib";
import DtoTable from "../../components/dto-table/dto-table";
import ItemModel from "../../models/dto/item-model"
import ButtonWithIcon from "../../components/buttons/button-with-icon";

const ItemsScreen: FC = () => {

    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "");
    const [loading, setLoading] = useSafeState(false);
    const [trigger, setTrigger] = useSafeState(false);
    const cargoText = <Trans>New Cargo</Trans>

    const itemDrawer = Drawer(
        {
            title: cargoText,
            children:
                <ItemDrawer/>,
            customClose: () => setTrigger(!trigger)
        }
    );

    const deleteRecord = (record: any) => {
        console.log(record);
        if (axiosInstance.current != null) {
            setLoading(true);
            axiosInstance.current.delete("/resource/items/" + encodeURIComponent(record.itemNo), {})
                .then(function (response: AxiosResponse<any>) {
                    setLoading(false);
                    message.success(antdContext('Az adott sor sikeresen törölve lett.'));
                    setTrigger(false);
                    setTrigger(!trigger);
                }).catch(function (error: AxiosError) {
                    console.log(error)
                    setLoading(false);
                });
        }
    }

    const handleDelete = (record: any) => {
        if (axiosInstance.current != null) {
            axiosInstance.current
                .delete('/resource/items/id?id=' + encodeURIComponent(record.itemNo))
                .then(() => {
                    openNotification('customSuccess', 'Az adott sor sikeresen törölve lett.')
                    setTrigger(false);
                    setTrigger(!trigger);
                })
                .catch(function (error: any) {
                    openNotification('error', error);
                    setTrigger(false);
                    setTrigger(!trigger);
                });
        }
    };
    const action = (record: any) => {

        return (
            <>
                <Tooltip title={<Trans>View</Trans>}>
                    <EditOutlined className={"ActionButtons"} style={{color: "#40a9ff"}}
                                  onClick={() => itemDrawer
                                      .open(
                                          "Edit",
                                          {
                                              itemNo: record.itemNo,
                                              manufacturer: record.manufacturer,
                                              quality: record.quality,
                                              sizeX: record.sizeX,
                                              sizeY: record.sizeY,
                                              normWeight: record.normWeight,
                                              version: record.version,
                                              mode: 'Edit'
                                          })
                                  }
                    />
                </Tooltip>
                <Tooltip title={<Trans>Remove</Trans>}>
                    <Popconfirm
                        title={<Trans>Are you sure want to delete record?</Trans>}
                        onConfirm={() => handleDelete(record)}
                        okText={<Trans>Yes</Trans>}
                        cancelText={<Trans>No</Trans>}>
                        <DeleteTwoTone className={"ActionButtons"} twoToneColor={"red"}/>
                    </Popconfirm>

                </Tooltip>

            </>
        )
    };

    const actions = {
        width: 200,
        render: (text: string, record: any) => action(record),
        title: '',
        fixed: 'right',
        align: 'center'
    };

    const header = (
        <>
            <Button type={'primary'} onClick={() => itemDrawer.open('Add new')} style={{ float: 'left' }}>
                <ButtonWithIcon icon={<PlusOutlined />} text={<Trans>Új hozzáadása</Trans>} />
            </Button>
            <Divider type="vertical" style={{ float: 'left', fontSize: 'xxx-large' }} />
            <Button onClick={() => itemDrawer.open('Upload File')} style={{ float: 'left' }}>
                <ButtonWithIcon
                    icon={<UploadOutlined />}
                    text={<Trans>Csv Feltöltés</Trans>}
                />
            </Button>
            <Divider type="vertical" style={{ float: 'left', fontSize: 'xxx-large' }} />
        </>
    );


    return (<>

            <>

                <Breadcrumb style={{marginBottom: 10}}>
                    <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
                    <Breadcrumb.Item><Trans id={'Items'}></Trans></Breadcrumb.Item>
                </Breadcrumb>
                <DtoTable
                    tableHeader={header}
                    model={ItemModel}
                    action={actions}
                    trigger={trigger}
                    allowRefreshButton={true}
                    pageSize={100}
                    scroll={{x: 1300, y: "65vh"}}/>
                {itemDrawer.component}
            </>
        </>
    )
}
export default ItemsScreen;
