import useSafeState from '../../utils/safe-state';
import React, {useEffect, useRef} from "react";
import {Form, Row, Col, Input, Button, message, Card, Spin, Tooltip, Select} from 'antd';
import {Trans} from "@lingui/macro";
import {useAxios} from "../../utils/hooks";
import { AxiosResponse, AxiosError } from 'axios';

import {antdContext} from "../../utils/antdContext";
import { InputNumber } from 'antd';
import { openNotification } from '../../utils/openNotification';
import {InfoCircleTwoTone} from "@ant-design/icons/lib";
import {CargoModel} from "../../models/cargo/cargo";
import {ItemModel} from "#/models/items/item";



interface Interface{
    data?: CargoModel
    cargoId?: any;
    mode?: "View" | "AddNew" | "Edit";
}

const NewCargoForm = (props : Interface) => {

    const data: any = props.data || undefined;

    const { TextArea } = Input;

    const [form] = Form.useForm();
    
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "");

    
    const [loading, setLoading] = useSafeState(false);
    const [isView, setIsView] = useSafeState<boolean>();
    const [isEdit, setIsEdit] = useSafeState<boolean>();
    
    const [cargoId, setCargoid] = useSafeState<string>();
    const [isSaveDisable, setIsSaveDisable] = useSafeState<boolean>(false);

    const [items, setItems] = useSafeState<any>([]);
    const [rawItems, setRawItems] = useSafeState<any>([]);
    const [itemSearch, setItemSearch] = useSafeState<any>();
    const [selectedItem, setSelectedItem] = useSafeState<any>();


    const quantityOnChange = (value: number) => {
        form.setFieldsValue({'quantity':value});
    };

    const init = () => {
        form.setFieldsValue({'quantity':'1'});
        let params:any = JSON.parse(JSON.stringify(props));
        let initMode:any = params.data == undefined ? props.mode : params.data.mode;
        let initCargoId:any = params.data == undefined ? props.cargoId : params.data.cargoId;
        setIsView(initMode == 'View' ? true : false);
        setIsEdit(initMode == 'Edit' ? true : false);
        setCargoid(initCargoId);

    }

    const getItems = (itemNo?: any) => {
        if (itemNo !== '') {
            if (axiosInstance.current != null) {
                axiosInstance.current
                    .post('/resource/items/page', {
                        start: 0,
                        length: 50,
                        direction: 'asc',
                        sorted_field: 'itemNo',
                        query: {
                            itemNo: itemNo,
                        },
                    })
                    .then(function (response: AxiosResponse<any>) {
                        setRawItems(response.data.data);
                        const itemOptions = generateOptionsByLabelAndValue(response.data.data, 'manufacturer', 'itemNo');
                        setItems(itemOptions);
                    })
                    .catch(function (error: any) {
                        console.log(error);
                    });
            }
        }
    };

    const generateOptionsByLabelAndValue = (data: any, label: string, val: string): optionalType[] => {
        let arrayToOptions = new Array<optionalType>();
        for (var i = 0; i < data.length; i++) {
            let currentItem: optionalType = { value: data[i][val], label: data[i][val] + ' || ' + data[i][label] };
            arrayToOptions.push(currentItem);
        }
        return arrayToOptions;
    };

    type optionalType = {
        key?: number;
        label: any;
        value: string;
    };

    useEffect(() => {
        getItems('');

        form.resetFields();

        if (props.mode === "Edit") {

            let fieldsValues = [];
            for (const [key, value] of Object.entries(data.data)) {
                fieldsValues.push({name: key, value: value});
                if(key == 'item'){
                    handleItemChange(value);
                }
            }
            form.setFields(fieldsValues);
        }

    }, []);

    useEffect(() => {
        if (isView || isEdit) {

        }
    },[isView]
    );


    useEffect(() => {
        if (itemSearch != '') {
            getItems(itemSearch);
        }
    }, [itemSearch]);

    const handleFinish = () => {
        

        if(axiosInstance.current != null) {
            setLoading(true);                    
                    
            axiosInstance
                .current
                .post(
                    "/resource/cargo",
                {
                    id: form.getFieldValue(["id"]),
                    cargoName: form.getFieldValue(["cargoName"]),
                    quantity: form.getFieldValue(["quantity"]),
                    needToDeliveryQuantity: form.getFieldValue(["needToDeliveryQuantity"]),
                    deliveredQuantity: form.getFieldValue(["deliveredQuantity"]),
                    uniqueId: form.getFieldValue(["uniqueId"]),
                    weight: form.getFieldValue(["weight"]),
                    comment: form.getFieldValue(["comment"]),
                    item: {
                        itemNo: selectedItem['itemNo']
                    }
                }
                ).then(function (response : AxiosResponse<any>)
                {      
                    openNotification('success','');
                    setLoading(false);
                    setIsSaveDisable(true);
                    onReset();
                }).catch(function (error :AxiosError) {
                    let errResp = JSON.parse(JSON.stringify(error.response));
                    let errMessage = errResp.data.message;
                    openNotification('error',errMessage);
                setLoading(false);
            });

        }
    }

    const handleUpdate = () => {

        if(axiosInstance.current != null) {
            setLoading(true);

            axiosInstance
                .current
                .put(
                    "/resource/cargo/id?id=" + encodeURIComponent(form.getFieldValue(['id'])),
                    {
                        id: form.getFieldValue(["id"]),
                        cargoName: form.getFieldValue(["cargoName"]),
                        quantity: form.getFieldValue(["quantity"]),
                        needToDeliveryQuantity: form.getFieldValue(["needToDeliveryQuantity"]),
                        deliveredQuantity: form.getFieldValue(["deliveredQuantity"]),
                        uniqueId: form.getFieldValue(["uniqueId"]),
                        ownUniqueId: form.getFieldValue(["ownUniqueId"]),
                        weight: form.getFieldValue(["weight"]),
                        comment: form.getFieldValue(["comment"]),
                        item: {
                            itemNo: form.getFieldValue(["item"])
                        },
                        status: form.getFieldValue(["status"]),
                        version: form.getFieldValue(["version"])
                    }
                ).then(function (response : AxiosResponse<any>) {
                    openNotification('success','');
                    setLoading(false);
                    setIsSaveDisable(true);
                    onReset();
                }).catch(function (error :AxiosError) {
                    let errResp = JSON.parse(JSON.stringify(error.response));
                    let errMessage = errResp.data.message;
                    openNotification('error',errMessage);
                }).finally(() => {
                    setLoading(false);
                });

        }
    }

    const createQualityField = () => {
        if (selectedItem) {
            return (
                <>
                    {selectedItem['quality']
                        ? selectedItem['quality']
                        : '-'}
                </>
            );
        } else {
            return <>{data && data['quality'] ? data['quality'] : '-'}</>;
        }
    };

    const createNormWeightField = () => {
        if (selectedItem) {
            return (
                <>
                    {selectedItem['normWeight']
                        ? selectedItem['normWeight']
                        : '-'}
                </>
            );
        } else {
            return <>{data && data['normWeight'] ? data['normWeight'] : '-'}</>;
        }
    };

    const createSizeXField = () => {
        if (selectedItem) {
            return (
                <>
                    {selectedItem['sizeX']
                        ? selectedItem['sizeX']
                        : '-'}
                </>
            );
        } else {
            return <>{data && data['sizeX'] ? data['sizeX'] : '-'}</>;
        }
    };

    const createSizeYField = () => {
        if (selectedItem) {
            return (
                <>
                    {selectedItem['sizeY']
                        ? selectedItem['sizeY']
                        : '-'}
                </>
            );
        } else {
            return <>{data && data['sizeY'] ? data['sizeY'] : '-'}</>;
        }
    };

    const handleItemChange = (value: any) => {

        form.resetFields(['quality', 'normWeight', 'sizeX', 'sizeY']);
        console.log(rawItems);
        for(let i = 0; i < rawItems.length; i++){

            if(rawItems[i]['itemNo'] == value) {
                setSelectedItem(rawItems[i]);
                return;
            }
        }
    };


    const onReset = () => {
        setSelectedItem(null);
        form.resetFields();
        form.resetFields(['quality', 'normWeight', 'sizeX', 'sizeY']);
    };


    return(
        
                <Form id="newcargo"  layout='vertical' form={form}
                      onFinish={() => props.mode === "Edit" ? handleUpdate() : handleFinish()}>
                <Spin spinning={loading}></Spin>
                <>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="uniqueId"
                                label={<Trans>UniqueId</Trans>}
                                children={<Input disabled={isView}/>}
                                rules={[{
                                    type:"string",
                                    required: true,
                                    message: <Trans>Please enter the id</Trans>
                                }]}
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="cargoName"
                                label={<Trans>Cargo Name</Trans>}
                                children={<Input disabled={isView}/>}
                                rules={[{
                                    type:"string",
                                    required: true,
                                    message: <Trans>Please enter the cargo name</Trans>
                                }]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="item"
                                label={
                                    <>
                                        <Trans>Item</Trans>
                                        <Tooltip
                                            placement="top"
                                            title={
                                                <span>
                                            A lista maximum 50 elemet jelenít meg, és a tartalma a beírt szöveg
                                            alapján módosul.
                                        </span>
                                            }
                                            children={
                                                <InfoCircleTwoTone twoToneColor="#1890ff" style={{ marginLeft: 5 }} />
                                            }
                                        />
                                    </>
                                }
                                children={
                                    <Select
                                        allowClear
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder={<Trans>Select an Item</Trans>}
                                        onSearch={value => setItemSearch(value)}
                                        onChange={value => handleItemChange(value)}
                                        filterOption={false}
                                        options={items}
                                    ></Select>
                                }
                                rules={[
                                    {
                                        required: false,
                                        message: <Trans>Please enter a shop</Trans>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                name="quality"
                                label={
                                    <b>
                                        <Trans>Quality</Trans>:
                                    </b>
                                }
                                children={createQualityField()}
                            />
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="normWeight"
                                label={
                                    <b>
                                        <Trans>Norm Weight</Trans>:
                                    </b>
                                }
                                children={createNormWeightField()}
                            />
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="sizeX"
                                label={
                                    <b>
                                        <Trans>Size X</Trans>:
                                    </b>
                                }
                                children={createSizeXField()}
                            />
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="sizeY"
                                label={
                                    <b>
                                        <Trans>Size Y</Trans>:
                                    </b>
                                }
                                children={createSizeYField()}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="weight"
                                label={<Trans>Weight</Trans>}
                                children={<InputNumber disabled={isView} defaultValue={1} min={1}/>}
                                rules={[{
                                    type:"number",
                                    required: true,
                                    message: <Trans>Please enter the quantity</Trans>,
                                    validator: () => {

                                        let v:string = form.getFieldValue('weight');

                                        if (v == undefined || v.length <= 0) {
                                            return Promise.reject('');
                                        } else {
                                            return Promise.resolve('');
                                        }
                                    }
                                }]}
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="quantity"
                                label={<Trans>Quantity</Trans>}
                                children={<InputNumber disabled={isView} defaultValue={1} min={1} onChange={quantityOnChange}/>}
                                rules={[{
                                    type:"number",
                                    required: true,
                                    message: <Trans>Please enter the quantity</Trans>,
                                    validator: () => {

                                        let v:string = form.getFieldValue('quantity');

                                        if (v == undefined || v.length <= 0) {
                                            return Promise.reject('');
                                        } else {
                                            return Promise.resolve('');
                                        }
                                    }
                                }]}

                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="comment"
                                label={<Trans>Comment</Trans>}
                                children={<TextArea disabled={isView} rows={4}/>}
                            />
                        </Col>
                    </Row>
                </>
                {props.mode === "Edit" ?
                    <Button loading={loading} htmlType="button" type="primary" onClick={() => form.submit()}>
                        <Trans>Mentés</Trans>
                    </Button>
                    :
                        <>
                            <Button htmlType="button" style={{marginRight: 8}} onClick={() => onReset()}>
                                <Trans>Vissza</Trans>
                            </Button>
                            <Button loading={loading} htmlType="button" type="primary" style={{marginRight: 8}}
                                    onClick={() => form.submit()}>
                                <Trans>Save</Trans>
                            </Button>
                        </>
                }

            </Form>
            );
            

}
export default NewCargoForm;
