import useSafeState from '../../utils/safe-state';
import {Dropdown, Image, Menu} from "antd";
import React from "react";
import { Language } from "../../models/language/language";

interface Interface {
    activeLanguage: string;
    languages: Language[];
    handleLanguageChange?: (e:any) => void;
}

const LanguageChange = (props : Interface) => {

    const [visible,setVisible] = useSafeState(false);

    const handleVisibleChange = (flag:boolean) => {
        setVisible(flag);
    };
    const menu = (
        <Menu onClick={props.handleLanguageChange}>
            {props.languages.map(value => {
                return(
                    <Menu.Item key={value.language}>
                        <Image src={value.icon} preview={false} alt={value.language} />
                    </Menu.Item>
                )
            })}
        </Menu>
    );
    const active:any=props.languages.find(value => value.language === props.activeLanguage);
    return (
        <Dropdown
            overlay={menu}
            onVisibleChange={handleVisibleChange}
            visible={visible}
        >
            <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                <Image src={active.icon} preview={false} alt={active.language}/>
            </span>
        </Dropdown>
    );

};
export default LanguageChange;
