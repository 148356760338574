import React, {FC} from "react";
import useSafeState from "../../utils/safe-state";
import {Trans} from "@lingui/macro";
import {Breadcrumb} from "antd";
import CameraReadingModel from "../../models/dto/camerareadings-model"
import DtoTable from "../../components/dto-table/dto-table";

const CameraReadingsScreen: FC = () => {

    const [trigger, setTrigger] = useSafeState(false);

    return (<>

            <>

                <Breadcrumb style={{marginBottom: 10}}>
                    <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
                    <Breadcrumb.Item><Trans id={'CameraReadings'}></Trans></Breadcrumb.Item>
                </Breadcrumb>
                <DtoTable
                    model={CameraReadingModel}
                    trigger={trigger}
                    allowRefreshButton={true}
                    pageSize={100}
                    scroll={{x: 1300, y: "65vh"}}/>
            </>
        </>
    )
}
export default CameraReadingsScreen;
