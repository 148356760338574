import React from "react";
import {FC} from "react";
import DtoTable from "../../components/dto-table/dto-table";
import DeliveryOrderListModel from "../../models/dto/delivery-order-model";
import useSafeState from "../../utils/safe-state";
import {Modal, Breadcrumb, Tooltip, Button,  message, Popconfirm} from "antd";
import {Trans} from "@lingui/macro";
import {useAxios} from "../../utils/hooks";
import { AxiosResponse, AxiosError } from 'axios';
import {antdContext} from "../../utils/antdContext";
import { openNotification } from '../../utils/openNotification';

import {
    DeleteTwoTone, EditOutlined
} from "@ant-design/icons/lib";


import {PlusOutlined} from "@ant-design/icons/lib";
import NewDeliveryForm from "../../forms/delivery-form/new-delivery-form";
import Drawer from "../../components/drawer/drawer";

const DeliveryOrderListScreen : FC = () => {

    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "");
    const [loading, setLoading] = useSafeState(false);
    const [trigger, setTrigger] = useSafeState(false);
    const [viewDeliveryId, setViewDeliveryId] = useSafeState<any>();
    
    const newDeliveryDrawer = Drawer(
        {
            title: <Trans>Delivery</Trans>,
            children:
                <NewDeliveryForm/>,
            customClose: () => setTrigger(!trigger)
        }
    );

    const action =  (record: any) => {

            return (
            <>
                <Tooltip  title={<Trans>Edit</Trans>}>
                    <EditOutlined disabled={record.status != 'DELIVERED'} className={"ActionButtons"} style={{color: record.status != 'DELIVERED' ? "#40a9ff" : "gray"}} onClick={() => record.status != 'DELIVERED' ? newDeliveryDrawer.open("Edit", {deliveryId: record.id}) : ""}/>
                </Tooltip>
                <Tooltip placement="top" title={<Trans>Remove</Trans>}>
                <Popconfirm  title={record.status != 'DELIVERED' ? <Trans>Are you sure want to delete record?</Trans>:<Trans>Delivered record cannot be deleted</Trans>}
                            onConfirm={() => record.status != 'DELIVERED' ? handleDeleteOk(record.id) : ""}
                            okText={<Trans>Yes</Trans>}
                            cancelText={<Trans>No</Trans>}> 
                    <DeleteTwoTone disabled={record.status != 'DELIVERED'} className={"ActionButtons"} twoToneColor={record.status != 'DELIVERED' ? "red" : "gray"}/>
                </Popconfirm>
                
            </Tooltip>
            </>
    )};

    const actions = {
        width: 110,
        render: (text: string, record: any) => action(record),
        title: '',
        fixed: 'right',
        align: 'center'
    };

    const handleDeleteOk = (deleteid: any) => {
        
        if(axiosInstance.current != null) {
            setLoading(true);
            axiosInstance.current.delete("/resource/delivery/deleterecord?deliveryId=" + deleteid, {})
                .then(function (response : AxiosResponse<any>) {
                    setLoading(false);
                    message.success(antdContext('Az adott sor sikeresen törölve lett.'));
                    setTrigger(false);
                    setTrigger(!trigger);
                }).catch(function (error :AxiosError) {
                    let errResp = JSON.parse(JSON.stringify(error.response));
                    let errMessage = errResp.data.message;
                    openNotification('error',errMessage);
                    console.log(error)
                    setLoading(false);
            });
        }
    }


    return (<>
        <>
        
            <Breadcrumb style={{ marginBottom: 10 }}>
                <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
                <Breadcrumb.Item><Trans id={'Delivery order list'}></Trans></Breadcrumb.Item>
            </Breadcrumb>
            <DtoTable
                tableHeader={
                    <Button type={"primary"} onClick={ () => {
                            newDeliveryDrawer.open("", {deliveryId: viewDeliveryId, mode: 'AddNew'});
                        }
                    }
                            style={{float: "left", marginRight:10}}>
                        <PlusOutlined /><span><Trans>Add new delivery order</Trans></span>
                    </Button>}
                model={DeliveryOrderListModel}
                action={actions}
                allowRefreshButton={true}
                trigger={trigger}
                pageSize={100}
                scroll={{ x: 1300, y: "65vh" }}
            />
            
            {newDeliveryDrawer.component}
            </>

    </>
)
}
export default DeliveryOrderListScreen;
