import React from "react";
import {FC} from "react";
import DtoTable from "../../components/dto-table/dto-table";
import UsersListModel from "../../models/dto/users-list-model";
import useSafeState from "../../utils/safe-state";
import {Breadcrumb, Tooltip, Button, Popconfirm, message} from "antd";
import {Trans} from "@lingui/macro";
import {useAxios} from "../../utils/hooks";
import { AxiosInstance, AxiosResponse, AxiosError } from 'axios';

import {
    DeleteTwoTone,
    EditOutlined
} from "@ant-design/icons/lib";

import {useKeycloak} from "@react-keycloak/web";
import {PlusOutlined} from "@ant-design/icons/lib";
import Drawer from "../../components/drawer/drawer";
import NewUserDrawer from "../../forms/user-form/new-user-drawer";
import {antdContext} from "../../utils/antdContext";
import { openNotification } from '../../utils/openNotification';




const UsersListScreen : FC = () => {

    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "");
    const [loading, setLoading] = useSafeState(false);
    const [trigger, setTrigger] = useSafeState(false);
        
    const userText = <Trans>New User</Trans>

    const newUserDrawer = Drawer(
        {
            title: userText,
            children:
                <NewUserDrawer/>,
            customClose: () => setTrigger(!trigger)
        }
    );

    const deleteRecord = (record:any) => {
        if(axiosInstance.current != null) {
            setLoading(true);
            axiosInstance.current.delete("/resource/user/deleterecord?userName=" + record.loginName, {})
                .then(function (response : AxiosResponse<any>) {
                    setLoading(false);
                    message.success(antdContext('Az adott sor sikeresen törölve lett.'));
                    setTrigger(false);
                    setTrigger(!trigger);
                }).catch(function (error :AxiosError) {
                    let errResp = JSON.parse(JSON.stringify(error.response));
                    let errMessage = errResp.data.message;
                    openNotification('error',errMessage);
                    console.log(error)
                    setLoading(false);
            });
        }
    }

    const action =  (record: any) => {

            return (            

            <>
            
                <Tooltip  title={<Trans>Edit</Trans>}>
                       
                            <EditOutlined  className={"ActionButtons"} style={{color: "#40a9ff"}} onClick={()=>newUserDrawer.open("Edit",
                                {
                                            mode: 'Edit',
                                            loginName: record.loginName,
                            }
                      )
                  }/>
                </Tooltip>
                <Tooltip  title={<Trans>Remove</Trans>}>
                    <Popconfirm  title={<Trans>Are you sure want to delete record?</Trans>}
                            onConfirm={() => deleteRecord(record)}
                            okText={<Trans>Yes</Trans>}
                            cancelText={<Trans>No</Trans>}> 
                        <DeleteTwoTone className={"ActionButtons"} twoToneColor={"red"}/>
                    </Popconfirm>
                </Tooltip>
                </>
    )};

    const actions = {
        width: 110,
        render: (text: string, record: any) => action(record),
        title: '',
        fixed: 'right',
        align: 'center'
    };

    


    return (<>
    
        <>
        
            <Breadcrumb style={{ marginBottom: 10 }}>
                <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
                <Breadcrumb.Item><Trans id={'User list'}></Trans></Breadcrumb.Item>
            </Breadcrumb>
            <DtoTable
                tableHeader={<Button type={"primary"} onClick={()=>newUserDrawer.open("", {
                    mode: 'AddNew'
              })
          } style={{float: "left", marginRight:10}}><PlusOutlined /><span><Trans>Add new user</Trans></span></Button>}
                model={UsersListModel}
                action={actions}
                trigger={trigger}
                pageSize={100}
                scroll={{ x: 1300, y: "65vh" }}
                />
                {newUserDrawer.component}                
                </>
        
    
    </>
)
}
export default UsersListScreen;
