import React, {useEffect, useState} from 'react';
import {Form, Row, Col, Button, Checkbox, CheckboxProps, Upload} from 'antd';
import {useAxios} from '../../utils/hooks';
import {AxiosError} from "axios";
import {Trans} from "@lingui/macro";
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import {InboxOutlined} from "@ant-design/icons";


import { openNotification } from '../../utils/openNotification';


interface Interface {
    data?: any;
    onClose?: () => void;
}

const UploadFilesDrawer  = (props: Interface) => {

    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "")
    
    const [form] = Form.useForm();
    
    
    const { Dragger } = Upload;

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [uploading, setUploading] = useState(false);
    const [reopen, setReopen] = useState(false);
    const [hasfiles, setHasFiles] = useState(false);

    useEffect(() => {
        setHasFiles(fileList.length>0);
    },[fileList]

    );

    const uploadProps: UploadProps = {
        name: 'file',
        multiple: true,
        onChange(info) {
          const { status } = info.file;
          if (status !== 'uploading') {
            //console.log(info.file, info.fileList);
            
          }
          if (status === 'done') {
            //message.success(`${info.file.name} file uploaded successfully.`);
          } else if (status === 'error') {
            //message.error(`${info.file.name} file upload failed.`);
          }
        },
        onDrop(e) {
          //console.log('Dropped files', e.dataTransfer.files);
          
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            
            return false;
        },
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            
          },
        fileList,
    };

    const handleUpload = (cargoId:any) => {
        
        fileList.forEach((file) => {
          const uploadedFile = new FormData();
          uploadedFile.append('uploadedFile', file as RcFile);
            if (axiosInstance.current != null) {

                axiosInstance.current.post("/resource/cargo/upload-file?cargoId=" + cargoId + "&setToOk=" + reopen, uploadedFile,
                    { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
                        openNotification('success','successful');
                        setUploading(false);
                    }).catch(function (error :any) {
                        openNotification('error',error);
                        console.log(error)
                        setUploading(false);
                });
            }
        });
        setUploading(true);
        
        
    };


    const handleFinish = () => {
        
        if (fileList.length > 0) {
            handleUpload(props.data.cargoData.id);
        }        
    }


    const onChangeReopen: CheckboxProps['onChange'] = (e) => {
        setReopen(e.target.checked);
      };

    return(
        
        <Form id='upload-files'  form={form} layout="vertical" onFinish={() => handleFinish()}>
            {
                <>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="datafiles"
                            children={<Dragger {...uploadProps}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text"><Trans>Click or drag file to this area to upload</Trans></p>
                            </Dragger>
                            }
                           />
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="reopen"
                            children={
                                <Checkbox onChange={onChangeReopen}><Trans>Reopen</Trans></Checkbox>
                            }
                           />
                    </Col>
                </Row>
                    <Form.Item
                        children={
                            <>
                                <br/>
                                <Button disabled={!hasfiles} htmlType="button" type="primary" onClick={() => form.submit()}>
                                    <Trans>Upload</Trans>
                                </Button>
                            </>
                        }
                    />
                </>
            }
        </Form>
    );

}
export default UploadFilesDrawer;
